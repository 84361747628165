import { Component } from '@angular/core';

@Component({
  selector: 'app-changelog',
  standalone: true,
  imports: [],
  templateUrl: './changelog.component.html',
  styleUrl: './changelog.component.css'
})
export class ChangelogComponent {
    private readonly CLASSNAME: string = "hidden";
    //Expands and contract changelog notes for a specific entry
    public expandNotes(event: Event): void{
        let isExpanded: boolean = false;
        //Traverse up the dom to get the changelog entry's ID
        const target: HTMLElement = event.target as HTMLElement;
        const grandparent: HTMLElement = target.parentElement?.parentElement as HTMLElement; // Traverse up two levels

        //Update inner html of the header tag
        if(target.innerHTML === "-"){
            target.innerHTML = "+"; 
            isExpanded = false;
        }
        else {
            target.innerHTML = "-"; 
            isExpanded = true;
        }

        //Remove or add hidden class to changelog-body
        const changelogBody: HTMLElement = grandparent.lastChild as HTMLElement;
        if(changelogBody.classList.contains(this.CLASSNAME)) changelogBody.classList.remove(this.CLASSNAME);
        else changelogBody.classList.add(this.CLASSNAME);

    }
}
