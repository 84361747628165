import { ChordQuality } from "../../constants/ChordEnum";
import { Chord } from "../../models/Chord";
import { StaffElement } from "./StaffElement";
import { StaffNote } from "./StaffNote";

export class StaffChord extends StaffElement{
    private quality: ChordQuality;
    private notes: StaffNote[];
    private chord: Chord;

    constructor(chord: Chord, canvas: HTMLCanvasElement){
        super();
        this.chord = chord;
        this.quality = chord.getQuality;
        this.notes = [];

        if(chord.getNotes != null && chord.getNotes.length > 0){
            for(let note of this.chord.getNotes){
                const staffNote = new StaffNote(note, canvas);
                this.notes.push(staffNote);
            }
        }

    }

    public get getQuality(): ChordQuality {
        return this.quality;
    }
    public set setQuality(value: ChordQuality) {
        this.quality = value;
    } 

    public get getNotes(): StaffNote[] {
        return this.notes;
    }
    public set setNotes(value: StaffNote[]) {
        this.notes = value;
    }

    public get getChord(): Chord {
        return this.chord;
    }
    public set setChord(value: Chord) {
        this.chord = value;
    }

    get getName(): string{
        switch(this.quality){
            case 1:
                return "Major Triad";
            case 4: 
                return "Minor Triad";
            case 7: 
                return "Augmented Triad";
            case 10:
                return "Diminished Triad";
            case 13:
                return "Dominant Seventh";
            case 17:
                return "Major Seventh";
            case 21:
                return "Minor Seventh";
            case 25:
                return "Half-Diminished Seventh";
            case 29:
                return "Diminished Seventh";
            case 30:
                return "Major Ninth";
            case 35:
                return "Dominant Ninth";
            case 40:
                return "Minor Ninth";
            case 45:
                return "Dominant Seventh Flat 9";
            case 50:
                return "Dominant Seventh Sharp 9";
            case 55:
                return "Major Eleventh";
            case 60:
                return "Dominant Eleventh";
            case 65:
                return "Minor Eleventh";
            case 70:
                return "Dominant Seventh Sharp 11";
            case 75:
                return "Major Thirteenth";
            case 80: 
                return "Dominant Thirteenth";
            case 85:
                return "Minor Thirteenth";
            default:
                return "";
        }
    }
}