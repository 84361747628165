import { Note } from "../../models/Note";
import { StaffElement } from "./StaffElement";

export class StaffNote extends StaffElement{
    private fullName: string;
    private clef: boolean;
    private yPos: number = 10;
    private note: Note;

    constructor(note: Note, canvas: HTMLCanvasElement | null){
        super();
        this.fullName = note.getFullName;
        this.clef = note.getClef;
        if(canvas != null){
            this.yPos = this.calculateYPos(this.fullName, canvas!);
        }
        this.note = note;
    }

    get getFullName(): string{
        return this.fullName;
    }

    set setFullName(fullName: string){
        this.fullName = fullName;
    }

    get getYPos(): number{
        return this.yPos;
    }

    set setYPos(yPos: number){
        this.yPos = yPos;
    }

    get getNote(): Note{
        return this.note;
    }

    set setNote(note: Note){
        this.note = note;
    }

    get getClef(): boolean{
        return this.clef;
    }

    set setClef(clef: boolean){
        this.clef = clef;
    }

    get getName(): string{
        return this.fullName;
    }

    //Calculates the y position of the note and adjusts based on selected clef
    private calculateYPos(fullName: string, canvas: HTMLCanvasElement): number{
        let yPos: number = 0;
        const root: string = fullName[0];
        const octave: string = fullName[fullName.length - 1];
        const val: string = root + octave;

        
        const yOffset: number  = canvas.height / 10;
        const y: number = (canvas.height / 4) + yOffset;
        const yIncrement: number = parseFloat((canvas.height / 16).toFixed(3));
        const yIncrementHalf: number = parseFloat((yIncrement / 2).toFixed(3));
        const noteOffset: number = parseFloat((canvas.height / 4.75).toFixed(3));
        

        //Treble clef
        if(this.clef){
            switch(val){
                case "F3":
                    yPos =  (y + (7*yIncrement)) - noteOffset;
                    break;
                case "G3":
                    yPos =  (y + (7*yIncrement)) - yIncrementHalf - noteOffset;
                    break;
                case "A3":
                    yPos =  (y + (6*yIncrement)) - noteOffset;
                    break;
                case "B3":
                    yPos =  (y + (6*yIncrement)) - yIncrementHalf - noteOffset;
                    break;
                case "C4":
                    yPos =  (y + (5*yIncrement)) - noteOffset;
                    break;
                case "D4":
                    yPos =  (y + (5*yIncrement)) - yIncrementHalf - noteOffset;
                    break;
                case "E4":
                    yPos =  (y + (4*yIncrement)) - noteOffset;
                    break;
                case "F4":
                    yPos =  (y + (4*yIncrement)) - yIncrementHalf - noteOffset;
                    break;
                case "G4":
                    yPos =  (y + (3*yIncrement)) - noteOffset;
                    break;
                case "A4":
                    yPos =  (y + (3*yIncrement)) - yIncrementHalf - noteOffset;
                    break;
                case "B4":
                    yPos =  (y + (2*yIncrement)) - noteOffset;
                    break;
                case "C5":
                    yPos =  (y + (2*yIncrement)) - yIncrementHalf - noteOffset;
                    break;
                case "D5":
                    yPos =  (y + (1*yIncrement)) - noteOffset;
                    break;
                case "E5":
                    yPos =  (y + (1*yIncrement)) - yIncrementHalf - noteOffset;
                    break;
                case "F5":
                    yPos =  y - noteOffset;
                    break;
                case "G5":
                    yPos =  y -  yIncrementHalf - noteOffset;
                    break;
                case "A5":
                    yPos =  (y - (1*yIncrement)) - noteOffset;
                    break;
                case "B5":
                    yPos =  (y - (1*yIncrement)) - yIncrementHalf - noteOffset;
                    break;
                case "C6":
                    yPos =  (y - (2*yIncrement)) - noteOffset;
                    break;
                case "D6":
                    yPos =  (y - (2*yIncrement)) - yIncrementHalf - noteOffset;
                    break;
                case "E6":
                    yPos =  (y - (3*yIncrement)) - noteOffset;
                    break;
                default:
                    yPos =  10000;
            }
        //bass clef
        } else {
            switch(val){
                case "A1":
                    yPos =  (y + (7*yIncrement)) - noteOffset;
                    break;
                case "B1":
                    yPos =  (y + (7*yIncrement)) - yIncrementHalf - noteOffset;
                    break;
                case "C2":
                    yPos =  (y + (6*yIncrement)) - noteOffset;
                    break;
                case "D2":
                    yPos =  (y + (6*yIncrement)) - yIncrementHalf - noteOffset;
                    break;
                case "E2":
                    yPos =  (y + (5*yIncrement)) - noteOffset;
                    break;
                case "F2":
                    yPos =  (y + (5*yIncrement)) - yIncrementHalf - noteOffset;
                    break;
                case "G2":
                    yPos =  (y + (4*yIncrement)) - noteOffset;
                    break;
                case "A2":
                    yPos =  (y + (4*yIncrement)) - yIncrementHalf - noteOffset;
                    break;
                case "B2":
                    yPos =  (y + (3*yIncrement)) - noteOffset;
                    break;
                case "C3":
                    yPos =  (y + (3*yIncrement)) - yIncrementHalf - noteOffset;
                    break;
                case "D3":
                    yPos =  (y + (2*yIncrement)) - noteOffset;
                    break;
                case "E3":
                    yPos =  (y + (2*yIncrement)) - yIncrementHalf - noteOffset;
                    break;
                case "F3":
                    yPos =  (y + (1*yIncrement)) - noteOffset;
                    break;
                case "G3":
                    yPos =  (y + (1*yIncrement)) - yIncrementHalf - noteOffset;
                    break;
                case "A3":
                    yPos =  y - noteOffset;
                    break;
                case "B3":
                    yPos =  y -  yIncrementHalf - noteOffset;
                    break;
                case "C4":
                    yPos =  (y - (1*yIncrement)) - noteOffset;
                    break;
                case "D4":
                    yPos =  (y - (1*yIncrement)) - yIncrementHalf - noteOffset;
                    break;
                case "E4":
                    yPos =  (y - (2*yIncrement)) - noteOffset;
                    break;
                case "F4":
                    yPos =  (y - (2*yIncrement)) - yIncrementHalf - noteOffset;
                    break;
                case "G4":
                    yPos =  (y - (3*yIncrement)) - noteOffset;
                    break;
                default:
                    yPos =  10000;
            }
        }

        return parseFloat(yPos.toFixed(3));
    }
}