import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { StaffGameHandlerComponent } from '../../staff-game-handler/staff-game-handler.component';
import { GameId } from '../../../constants/GameIdEnum';
import { StaffBuilder } from '../../../staff-graphics/StaffBuilder';
import { Staff } from '../../../staff-graphics/models/Staff';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SharedDataService } from '../../../util/SharedDataService';
import { StaffChord } from '../../../staff-graphics/models/StaffChord';
import { Chord } from '../../../models/Chord';


@Component({
  selector: 'app-staff-identification-chord-game',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './../../staff-game-handler/staff-game-handler.component.html',
  styleUrls: ['./../../staff-game-handler/staff-game-handler.component.css', "./staff-identification-chord-game.component.css",
              './../../game-handler.component.css']
})
export class StaffIdentificationChordGameComponent extends StaffGameHandlerComponent implements OnInit, OnDestroy  {
    public override gameId = GameId.StaffIdentification_Chords;
    override initialCountdown: number = 300;
    private staff: Staff = new Staff([4, 4], []);

    constructor(protected override router: Router, protected override sharedDataService: SharedDataService, protected override dialog: MatDialog, 
        protected override route: ActivatedRoute, @Inject(PLATFORM_ID) private platformId: Object){
        super(router, sharedDataService, dialog, route);
    }
    
    public override ngOnInit(): void {
        //Load images before initializing the module
        this.route.data.subscribe(data => {
            this.images = data['images'];
        })
        this.initModule();
    }

    public async ngAfterViewInit(){
        if(isPlatformBrowser(this.platformId)){
            this.setCanvasSize(); 
            const chord: Chord = this.generateRandomChord(this.isTrebleClef);
            this.randomElement = new StaffChord(chord, this.canvas.nativeElement);
            this.builder = new StaffBuilder(this.staff, this.canvas.nativeElement, this.gameId);
            await this.builder.loadFont();
            this.builder.drawSimpleStaff(this.randomElement, this.isTrebleClef);
            //Reload after NotoMusic font loads
            setTimeout(async () =>{
                this.canvas.nativeElement.getContext('2d')?.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
                await this.builder.drawSimpleStaff(this.randomElement, this.isTrebleClef);
            }, 700);

        }

    }

    protected override check(guess: number | string, buttonRef: HTMLButtonElement){
        this.checkAnswer(guess, buttonRef);
    }
}
