import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
// import * as paper from 'paper';
// import { Color } from 'paper/dist/paper-core';

@Component({
  selector: 'app-canvas-test',
  standalone: true,
  imports: [],
  templateUrl: './canvas-test.component.html',
  styleUrl: './canvas-test.component.css'
})
export class CanvasTestComponent implements AfterViewInit{

    @ViewChild('staffCanvas') staffCanvas!: ElementRef<HTMLCanvasElement>;


    constructor(@Inject(PLATFORM_ID) protected platformId: Object){
               
    }
    public ngAfterViewInit(): void {
        if(isPlatformBrowser(this.platformId)){
            const canvas = this.staffCanvas.nativeElement;
            const ctx = canvas.getContext("2d");
            const container = document.getElementById("canvas-container");
            const pixelRatio = window.devicePixelRatio || 1;

            // Set canvas width and height based on container size
            canvas.width = container!.offsetWidth * pixelRatio;
            canvas.height = container!.offsetHeight * pixelRatio;
            ctx!.scale(pixelRatio, pixelRatio);
            // const canvas = this.staffCanvas.nativeElement;
            // const ctx = canvas.getContext("2d");
            // const pixelRatio = window.devicePixelRatio || 1;
            // canvas.width = window.innerWidth * pixelRatio;
            // canvas.height = window.innerHeight * pixelRatio;
            // canvas.style.width = `${window.innerWidth}px`;
            // canvas.style.height = `${window.innerHeight}px`;

            if(ctx != null){
                ctx.scale(pixelRatio, pixelRatio);
                this.draw(canvas, ctx);
            }

        } 
    }

    public draw(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D){
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        let x: number = 5;
        let y: number = 10;
        ctx.fillRect(x, y, canvas.width, 10);
    }

}
