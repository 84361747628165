import { IntervalQuality } from "../../constants/IntervalQualityConstants";
import { Interval } from "../../models/Interval";
import { StaffElement } from "./StaffElement";
import { StaffNote } from "./StaffNote";

//Model class that handles cartesian position of an interval object
export class StaffInterval extends StaffElement{
    private quality: IntervalQuality;
    private note1: StaffNote;
    private note2: StaffNote;
    private interval: Interval;

    constructor(interval: Interval, canvas: HTMLCanvasElement){
        super();
        this.note1 = new StaffNote(interval.getNote1, canvas);
        this.note2 = new StaffNote(interval.getNote2, canvas);
        this.interval = interval;
        this.quality = this.interval.getQuality;
    }

    get getQuality(): IntervalQuality{
        return this.quality;
    }

    set setQuality(quality: IntervalQuality){
        this.quality = quality;
    }

    get getNote1(): StaffNote{
        return this.note1;
    }

    set setNote1(note: StaffNote){
        this.note1 = note;
    }

    get getNote2(): StaffNote{
        return this.note2;
    }

    set setNote2(note: StaffNote){
        this.note2 = note;
    }

    get getInterval(): Interval{
        return this.interval;
    }

    set setInterval(interval: Interval){
        this.interval = interval;
    }

    get getName(): string{
        switch(this.quality){
            case IntervalQuality.MINOR_SECOND:
                return "Minor 2nd";
            case IntervalQuality.MAJOR_SECOND:
                return "Major 2nd";
            case IntervalQuality.MINOR_THIRD:
                return "Minor 3rd";
            case IntervalQuality.MAJOR_THIRD:
                return "Major 3rd";
            case IntervalQuality.PERFECT_FOURTH:
                return "Perfect 4th";
            case IntervalQuality.TRITONE:
                return "Tritone";
            case IntervalQuality.PERFECT_FIFTH:
                return "Perfect 5th";
            case IntervalQuality.MINOR_SIXTH:
                return "Minor 6th";
            case IntervalQuality.MAJOR_SIXTH:
                return "Major 6th";
            case IntervalQuality.MINOR_SEVENTH:
                return "Minor 7th";
            case IntervalQuality.MAJOR_SEVENTH:
                return "Major 7th";
            case IntervalQuality.OCTAVE:
                return "Octave";
            default:
                return "";
        }
    }
}