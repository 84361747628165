import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedDataService } from '../util/SharedDataService';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { interval } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { ChordIdentifierComponent } from "./ear-training/chord-identifier/chord-identifier.component";
import { GameHandler } from "./GameHandler";
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { PianoUtil } from '../util/PianoUtil';



@Component({
    selector: 'app-game-handler',
    standalone: true,
    template: `<div>
                    <ng-content>
                    </ng-content> <!-- Place for projected content -->
                    <ng-container *ngIf="gameId == 1">
                        <div></div> 
                    </ng-container>
                    <ng-container *ngIf="gameId == 2">
                        <div></div>
                    </ng-container>
                </div>`,
    styleUrl: './game-handler.component.css',
    imports: [CommonModule, MatDialogModule, CommonModule]
})
export class GameHandlerComponent extends GameHandler implements OnInit, OnDestroy{
    protected audioFileLength: number = 2000;
    constructor(protected override router: Router, protected override sharedDataService: SharedDataService, protected override dialog: MatDialog,
                protected override route: ActivatedRoute
    ) {
        super(router, sharedDataService, dialog, route);
    }

    //Receives payload, redirects to start screen if payload is empty
    ngOnInit(): void {        
    }


    protected override checkAnswer(guess: number, buttonRef?: HTMLButtonElement): void {}
    protected override playSample(correct: boolean): void {}

    
    //Ends game and opens score sheet popup
    protected override finish(): void{
        PianoUtil.stopAllActiveSources();
        const payload = {
            scoreRows: this.scoreRows,
            score: this.score,
            gameId: this.gameId,
            difficulty: this.difficulty,
            time: this.secondsPassed,
            mode: this.challengeMode ? true : false

        }

        //Terminates observables
        this.stopTimer();

        //Opens score sheet dialog component
        this.handleDialog(payload);
    }

    //Stops audio context playback when user navigates away from game component
    protected terminateAudio(): void{
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (window.history.state && window.history.state.navigationId) {
                    // Check if navigationId is present to identify back/forward navigation
                    const isBackNavigation = event.navigationTrigger === 'popstate';
      
                    if (isBackNavigation) {
                        PianoUtil.stopAllActiveSources();
                    }
                }
            }
        });
    }

    
    //Flashes container red or green depending on user selection
    protected flash(result: boolean):void {
        if(result){
            this.flashGreen = true;
            this.flashRed = false;
            setTimeout(() => this.flashGreen = false, 500); 
        } else{
            this.flashRed = true;
            this.flashGreen = false;
            setTimeout(() => this.flashRed = false, 500);
        }
        
    }


    //Updates the score
    protected updateScore(correct: boolean): void{
        this.flash(correct);
        if(correct){
            this.correctGuesses += 1;
        }
        this.totalGuesses += 1;
    }

    //Passes sample length for loading bar
    protected updateBar(sampleLength: number): void{
        const intervalTime = 10;
        this.loading$ = interval(intervalTime).pipe(
            take(sampleLength / intervalTime),
            map(value => (value + 1) * (intervalTime / sampleLength) * 100)
        );
    }

    //Destroys subscription object when component is terminated
    ngOnDestroy() {
        this.stopTimer();
    }


}