import { Staff } from "./models/Staff";
import { StaffNote } from "./models/StaffNote";
import { Note } from "../models/Note";
import { StaffUtil } from "../util/StaffUtil";
import { StaffInterval } from "./models/StaffInterval";
import { StaffScale } from "./models/StaffScale";
import { StaffChord } from "./models/StaffChord";
import { GameId } from "../constants/GameIdEnum";
import { Interval } from "../models/Interval";
import { Chord } from "../models/Chord";
import { ChordQuality } from "../constants/ChordEnum";
import { StaffElement } from "./models/StaffElement";
import { ScaleQuality } from "../constants/ScaleQualityConstants";
import { Scale } from "../models/Scale";
import { CommonUtil } from "../util/CommonUtil";

//Handles the front end construction of a staff
export class StaffBuilder{
    
    private staff: Staff;
    private canvas: HTMLCanvasElement;
    private context: CanvasRenderingContext2D | null;
    private gameId: GameId;

    //Constant canvas elements
    private lineWidth: number = 560;
    private shortLineWidth: number = 40;
    private shortLineHeight: number = 2;
    private lineX: number = 10;
    private shortLineX: number = 290;
    private lineAbove3Y: number = 50;
    private lineAbove2Y: number = 70;
    private lineAbove1Y: number = 90;        
    private line1Y: number = 110;
    private line2Y: number = 130;
    private line3Y: number = 150;
    private line4Y: number = 170;
    private line5Y: number = 190;
    private lineBelow1Y: number = 210;
    private lineBelow2Y: number = 230;
    private lineBelow3Y: number = 250;

    //Threshold values
    private lineAbove3YThreshold: number = 50;
    private lineAbove2YThreshold: number = 70;
    private lineAbove1YThreshold: number = 90;        
    private line1YThreshold: number = 110;
    private line2YThreshold: number = 130;
    private line3YThreshold: number = 150;
    private line4YThreshold: number = 170;
    private line5YThreshold: number = 190;
    private lineBelow1YThreshold: number = 210;
    private lineBelow2YThreshold: number = 230;
    private lineBelow3YThreshold: number = 250;
    private offset: number = 3;

    //Canvas style dimensions
    public canvasStyleWidth: number = 750;
    public canvasStyleHeight: number = 500;

    //Symbol displacements
    private noteDisplacementX: number = this.shortLineX - 5;
    private noteDisplacementY: number = 54;
    private flatDisplacementX: number = 25;
    private sharpDisplacementX: number = 25;
    private flatDisplacementY: number = 10;
    private sharpDisplacementY: number = 15;
    private doubleFlatDisplacementX: number = 40;
    private doubleSharpDisplacementX: number = 30;
    private doubleFlatDisplacementY: number = 10;
    private doubleSharpDisplacementY: number = 47;

    //Global positioning variables
    private aspectRatio: number = 0;
    private yOffset: number = 0;
    private y: number = 0;
    private yInterval: number = 0;
    private yIntervalHalf: number = 0;
    private noteYOffset: number = 0;

    //Symbol fonts
    private wholeNoteFont = "140px 'Noto Music'";
    private flatFont = "55px 'Noto Music'";
    private sharpFont = "45px 'Noto Music'";
    private doubleFlatFont = "55px 'Noto Music'";
    private doubleSharpFont = "80px 'Noto Music'";
    private blackNoteFont = "165px 'Noto Music'";

    // private debouncedMousemove: ((event: MouseEvent) => void) | undefined;
    private blackNoteImage: HTMLImageElement = new Image();
    private blackNoteImage_rotated: HTMLImageElement = new Image();
    private greyNoteImage: HTMLImageElement = new Image();
    private greenNoteImage: HTMLImageElement = new Image();
    private greenNoteImage_rotated: HTMLImageElement = new Image();
    private redNoteImage: HTMLImageElement = new Image();
    private redNoteImage_rotated: HTMLImageElement = new Image();
    private wholeNoteImage: HTMLImageElement = new Image();
    private readonly wholeNoteSymbol: string = "\u{1D15D}";
    private readonly doubleSharpSymbol: string = "\uD834\uDD2A";
    private readonly sharpSymbol: string = "\u266F";
    private readonly flatSymbol: string = "\u266D";
    private readonly doubleFlatSymbol: string = "\uD834\uDD2B";
    private trebleClefImage = new Image();
    private bassClefImage = new Image(); 

    //Tracks pitch rotation for building game
    public currPitch: number = 0;

    //Tracks last mouse position
    // private currNote: StaffNote = new StaffNote(new Note("A0"), null);
    public noteCount: number = 1;

    //Flags status of mouseover event 
    private hoverEnabled: boolean = true;
    private clickEnabled: boolean = true;


    public drawnNotes: StaffNote[] = [];
    private displacementX: number = 0;
    public currentDisplacementX: number = 0;
    public currentDisplacement: boolean = false;

    //Stores image sources
    private imageSources: string[] = [];
    private imagePromises!: any;

    //X scalar values
    private xOffset: number = 0;
    private xScalar: number = 0;

    //Determines if user is on mobile/tablet device
    private isMobile: boolean = false;

    // Create canvas, context, and double buffer, and loads all staff symbols/images
    constructor(staff: Staff, canvas: HTMLCanvasElement, gameId: GameId){
        

        const pixelRatio = window.devicePixelRatio || 1;
        this.isMobile = pixelRatio > 1;
        this.staff = staff;
        this.canvas = canvas;
        this.context = this.canvas.getContext('2d');

        this.shortLineX = ((this.canvas.width) / 2) - (this.canvas.width / 32);
        this.noteDisplacementX = this.shortLineX;
        this.displacementX = this.isMobile ? this.canvas.width / 14 : this.canvas.width / 20;
        this.lineWidth = this.canvas.width;
        this.lineWidth -= this.lineX;
        this.gameId = gameId;

        //Parent div dimensions
        const rect = this.canvas.getBoundingClientRect();
        this.canvasStyleWidth = rect.width
        this.canvasStyleHeight = rect.height

        //Aspect ratio and y-axis values
        this.aspectRatio = this.canvas.height / this.canvas.width;
        this.yOffset = this.canvas.height / 10;
        this.y = (this.canvas.height / 4) + this.yOffset;
        this.yInterval = this.canvas.height / 16;
        this.yIntervalHalf = this.yInterval / 2;
        this.noteYOffset =  this.isMobile ? this.canvasStyleHeight / 32: this.canvasStyleHeight / 32;


        //X offset and scalar for scale games
        this.xOffset = this.canvas.width / 4.5;
        this.xScalar = this.canvas.width / 9.2;


        //Set the positions of the extended lines
        this.setExtendedLinesPosition();
        this.setSymbolFontSizes_nonscale();


        //Initialize and encode SVG string values
        const svgBlackNote: string = StaffUtil.constructSVGNote(this.canvas, pixelRatio, 'black', false);
        const svgBlackNote_rotated: string = StaffUtil.constructSVGNote(this.canvas, pixelRatio, 'black', true);
        const svgGreyNote: string = StaffUtil.constructSVGNote(this.canvas, pixelRatio, 'grey', false);
        const svgGreenNote: string = StaffUtil.constructSVGNote(this.canvas, pixelRatio, 'green', false);
        const svgGreenNote_rotated: string = StaffUtil.constructSVGNote(this.canvas, pixelRatio, 'green', true);
        const svgRedNote: string = StaffUtil.constructSVGNote(this.canvas, pixelRatio, 'red', false);
        const svgRedNote_rotated: string = StaffUtil.constructSVGNote(this.canvas, pixelRatio, 'red', true);

        
        this.blackNoteImage = new Image(); 
        this.blackNoteImage.src = 'data:image/svg+xml,' + encodeURIComponent(svgBlackNote);
        this.blackNoteImage.onload = () => {};

        this.blackNoteImage_rotated = new Image();
        this.blackNoteImage_rotated.src = 'data:image/svg+xml,' + encodeURIComponent(svgBlackNote_rotated);
        this.blackNoteImage_rotated.onload = () => {};

        this.greyNoteImage = new Image();
        this.greyNoteImage.src = 'data:image/svg+xml,' + encodeURIComponent(svgGreyNote);
        this.greyNoteImage.onload = () => {};

        this.greenNoteImage = new Image();
        this.greenNoteImage.src = 'data:image/svg+xml,' + encodeURIComponent(svgGreenNote);
        this.greenNoteImage.onload = () => {};

        this.greenNoteImage_rotated = new Image();
        this.greenNoteImage_rotated.src = 'data:image/svg+xml,' + encodeURIComponent(svgGreenNote_rotated);
        this.greenNoteImage_rotated.onload = () => {};

        this.redNoteImage = new Image();
        this.redNoteImage.src = 'data:image/svg+xml,' + encodeURIComponent(svgRedNote);
        this.redNoteImage.onload = () => {};

        this.redNoteImage_rotated = new Image();
        this.redNoteImage_rotated.src = 'data:image/svg+xml,' + encodeURIComponent(svgRedNote_rotated);
        this.redNoteImage_rotated.onload = () => {};

        this.wholeNoteImage = new Image();
        this.wholeNoteImage.src = 'assets/staff/whole-note.svg';
        this.wholeNoteImage.onload = () => {};

        this.trebleClefImage = new Image();
        this.trebleClefImage.src = 'assets/staff/treble-clef.png';
        this.trebleClefImage.onload = () => {};

        this.bassClefImage = new Image();
        this.bassClefImage.src = 'assets/staff/bass-clef.png';
        this.bassClefImage.onload = () => {};

        this.imageSources = [this.blackNoteImage.src, this.blackNoteImage_rotated.src, this.greyNoteImage.src,
            this.greenNoteImage.src, this.greenNoteImage_rotated.src, this.redNoteImage.src,
            this.redNoteImage_rotated.src, this.wholeNoteImage.src, this.trebleClefImage.src,
            this.bassClefImage.src];
        
        this.imagePromises = this.imageSources.map(src => this.loadImage(src));

    }

    public async loadFont(): Promise<void> {
        const fontFace: FontFace = new FontFace('Noto Music', 'url(/assets/fonts/noto-music-v20.woff2)');
        try {
            await fontFace.load();
            (document as any).fonts.add(fontFace);
            console.log("Noto Music font loaded");
            
            // Wait for the font to be available for rendering
            // await (document as any).fonts.ready;
        } catch (error) {
            console.error("Error loading font: ", error);
        }
    }

    //Draw accidental symbols offscreen on load to ensure font change
    private drawSymbolsOffScreen(): void{
        if(this.context){
            this.context.font = this.flatFont;
            this.context.fillText(this.flatSymbol, 10000, 10000);
            this.context.font = this.sharpFont;
            this.context.fillText(this.sharpSymbol, 10000, 10000);
            this.context.font = this.doubleFlatFont;
            this.context.fillText(this.doubleFlatSymbol, 10000, 10000);
            this.context.font = this.doubleSharpFont;
            this.context.fillText(this.doubleSharpSymbol, 10000, 10000);
        } 

    }

    //Set font sizes for non-scale games
    private setSymbolFontSizes_nonscale(): void{
        this.wholeNoteFont = this.canvas.width / 6.5 + "px Arial";
        this.flatFont = this.isMobile ? this.canvas.width / 5 + "px 'Noto Music'" : this.canvas.width / 8 + "px 'Noto Music'";
        this.sharpFont = this.isMobile ? this.canvas.width / 6 + "px 'Noto Music'" : this.canvas.width / 9 + "px 'Noto Music'";
        this.doubleFlatFont = this.isMobile ? this.canvas.width / 5 + "px 'Noto Music'" : this.canvas.width / 8 + "px 'Noto Music'";
        this.doubleSharpFont =  this.isMobile ? this.canvas.width / 5 + "px 'Noto Music'" :this.canvas.width / 7.5 + "px 'Noto Music'";
        
        //Set the symbol displacements 
        this.sharpDisplacementY = this.canvas.height / 4.15;
        this.flatDisplacementY = this.canvas.height / 4.1;
        this.doubleSharpDisplacementY = this.canvas.height / 4.1;       
        this.doubleFlatDisplacementY = this.canvas.height / 4.1;
        this.sharpDisplacementX = this.isMobile ? this.canvas.height / 14 : this.canvas.height / 20;
        this.flatDisplacementX = this.isMobile ? this.canvas.height / 16 : this.canvas.height / 16;
        this.doubleSharpDisplacementX = this.isMobile ? this.canvas.height / 14 : this.canvas.height / 14;
        this.doubleFlatDisplacementX = this.isMobile ? this.canvas.height / 12 : this.canvas.height / 12;
    }

    //Set font sizes for scale games
    private setSymbolFontSizes_scale(): void{
        this.wholeNoteFont = this.canvas.width / 8 + "px 'Noto Music'";
        this.flatFont = this.canvas.width / 9 + "px 'Noto Music'";
        this.sharpFont = this.canvas.width / 12 + "px 'Noto Music'";
        this.doubleFlatFont = this.canvas.width / 9 + "px 'Noto Music'";
        this.doubleSharpFont = this.canvas.width / 8 + "px 'Noto Music'";

        //Set the symbol displacements 
        this.sharpDisplacementY = this.canvas.height / 4.3;
        this.flatDisplacementY = this.canvas.height / 4.15;
        this.doubleSharpDisplacementY = this.canvas.height / 4.05;        
        this.doubleFlatDisplacementY = this.canvas.height / 4.15;
        this.sharpDisplacementX = this.canvas.height / 6.2;
        this.flatDisplacementX = this.canvas.height / 6;
        this.doubleSharpDisplacementX = this.canvas.height / 5.5;
        this.doubleFlatDisplacementX = this.canvas.height / 5.1;
    }

    public setExtendedLinesPosition(): void{
        const y: number = (this.canvas.height / 4) + this.yOffset;
        this.lineBelow3Y = y + (7 * this.yInterval);
        this.lineBelow2Y = y + (6 * this.yInterval);
        this.lineBelow1Y = y + (5 * this.yInterval);
        this.line5Y = y + (4 * this.yInterval);
        this.line4Y = y + (3 * this.yInterval);
        this.line3Y = y + (2 * this.yInterval);
        this.line2Y = y + (this.yInterval);
        this.line1Y = y;
        this.lineAbove1Y = y - (this.yInterval);
        this.lineAbove2Y = y - (2 * this.yInterval);
        this.lineAbove3Y = y - (3 * this.yInterval);

        const yOffset2: number = this.canvasStyleHeight / 10;
        const y2 = (this.canvasStyleHeight / 4) + yOffset2;
        const yInterval2: number = this.canvasStyleHeight / 16;
        this.lineBelow3YThreshold = y2 + (7 * yInterval2);
        this.lineBelow2YThreshold = y2 + (6 * yInterval2);
        this.lineBelow1YThreshold = y2 + (5 * yInterval2);
        this.line5YThreshold = y2 + (4 * yInterval2);
        this.line4YThreshold = y2 + (3 * yInterval2);
        this.line3YThreshold = y2 + (2 * yInterval2);
        this.line2YThreshold = y2 + (yInterval2);
        this.line1YThreshold = y2;
        this.lineAbove1YThreshold = y2 - (yInterval2);
        this.lineAbove2YThreshold = y2 - (2 * yInterval2);
        this.lineAbove3YThreshold = y2 - (3 * yInterval2);

        if(this.gameId === GameId.StaffBuilding_Scales || this.gameId === GameId.StaffIdentification_Scales){
            this.shortLineWidth = this.canvas.width / 15;
        } else {
            this.shortLineWidth = this.canvas.width / 11;
        }
        this.shortLineHeight = this.canvas.height / 150;
    }

    //Configures the staff game by drawing appropriate element structure based on game type
    private configureGame(staffElement: StaffElement): void{ //TODO: Research alternatives to avoid calling this method more than once
        const errorMsg: string = "Mismatch of game type and element type";
        if(this.gameId === GameId.StaffIdentification_Notes){
            if(staffElement instanceof StaffNote){
                this.drawnNotes.push(staffElement);
            } else {
                throw Error(errorMsg);
            }
        } else if(this.gameId === GameId.StaffIdentification_Intervals){
            
            if(staffElement instanceof StaffInterval){
                this.drawnNotes.push(staffElement.getNote1);
                this.drawnNotes.push(staffElement.getNote2);
            } else {
                throw Error(errorMsg);

            }
        } else if (this.gameId === GameId.StaffBuilding_Intervals){
            
            if(staffElement instanceof StaffInterval){
                //Only draw the root of the interval for the staff builder game
                this.drawnNotes.push(staffElement.getNote1);
            } else {
                throw Error(errorMsg);
            }

        }else if(this.gameId === GameId.StaffIdentification_Chords){
            
            if(staffElement instanceof StaffChord){
                for(let note of staffElement.getNotes){
                    this.drawnNotes.push(note);
                }
            } else {
                throw Error(errorMsg);

            }
        } else if(this.gameId === GameId.StaffBuilding_Chords) {      
            if(staffElement instanceof StaffChord){
                this.drawnNotes.push(staffElement.getNotes[0]);
            } else {
                throw Error(errorMsg);

            }
            
        } else if(this.gameId === GameId.StaffIdentification_Scales){
            if(staffElement instanceof StaffScale){
                this.setSymbolFontSizes_scale();
                for(let note of staffElement.getNotes){
                    this.drawnNotes.push(note);
                }
        
                // this.canvas.width = 1000;
                this.lineWidth = this.canvas.width;
                this.lineWidth -= this.lineX;
            } else {
                throw Error(errorMsg);

            }
        } else if (this.gameId === GameId.StaffBuilding_Scales){
            if(staffElement instanceof StaffScale){
                this.setSymbolFontSizes_scale();
                this.drawnNotes.push(staffElement.getNotes[0]);
                this.lineWidth = this.canvas.width;
                this.lineWidth -= this.lineX;
            } else {
                throw Error(errorMsg);
            }
        }
        else{
            throw Error("Game id was not found");
        }
    }

    private loadImage(src: string): Promise<HTMLImageElement>{
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = () => reject(new Error(`Failed to load image: ${src}`));
            img.src = src;
        });
    }


    //Draws a simple staff for each type of game
    public drawSimpleStaff(staffElement: any, clef: boolean): void{
        //Make sure note array is empty for each game instantiation
        this.drawSymbolsOffScreen();
        Promise.all(this.imagePromises).then(() =>{
            this.drawnNotes = [];
            this.configureGame(staffElement);
    
            //Draw the staff after populating the note array
            if(this.context != null){
                const canvas2: HTMLCanvasElement = document.createElement('canvas');
                canvas2.width = this.canvas.width;
                canvas2.height = this.canvas.height;
                const ctx2: CanvasRenderingContext2D = canvas2.getContext('2d') as CanvasRenderingContext2D;
                if(ctx2 != null){
                    this.context.drawImage(this.canvas, 0, 0);
                    this.drawCoreStaffElements(ctx2, clef);
                    this.context.drawImage(canvas2, 0, 0);
                } else{
                    throw Error("Context failed to initialize");
                }
            }
        });
        // this.loadFont().then(() =>{
        //     this.drawnNotes = [];
        //     this.configureGame(staffElement);
    
        //     //Draw the staff after populating the note array
        //     if(this.context != null){
        //         const canvas2: HTMLCanvasElement = document.createElement('canvas');
        //         canvas2.width = this.canvas.width;
        //         canvas2.height = this.canvas.height;
        //         const ctx2: CanvasRenderingContext2D = canvas2.getContext('2d') as CanvasRenderingContext2D;
        //         if(ctx2 != null){
        //             this.context.drawImage(this.canvas, 0, 0);
        //             this.drawCoreStaffElements(ctx2, clef);
        //             this.context.drawImage(canvas2, 0, 0);
        //         } else{
        //             throw Error("Context failed to initialize");
        //         }
        //     }
        // });
        // let test: FontFaceSet = await this.awaitFontReady();
        // console.log("PRINTING FONT FACE VALUES");
        // test.forEach((value) => {
        //     console.log(value.style);
        //     console.log(value.family);
        // })
        
        
    }

    
    //Redraws the staff
    public async refreshStaff(staffElement: any, clef: boolean){
        //Refresh note array and reconfigure the game
        this.drawnNotes = [];
        await this.configureGame(staffElement);

        //Draw the staff after populating the note array
        this.redrawStaff(clef);

    }

    //Event listener for the interval building mouseover event
    public setHoverEventListener(canvas: HTMLCanvasElement, clef: boolean, staffElement: StaffElement, event: Event, mouseY: number): void{
        this.hoverEnabled = true;
        if(this.hoverEnabled){
            const canvas2: HTMLCanvasElement = document.createElement('canvas');
            canvas2.width = this.canvas.width;
            canvas2.height = this.canvas.height;
            const ctx2: CanvasRenderingContext2D = canvas2.getContext('2d') as CanvasRenderingContext2D;
            
            if(ctx2 != null){
                if(staffElement instanceof StaffInterval){
                    this.highlightMouseOver(ctx2, mouseY, staffElement.getNote1, staffElement, clef);
                } else if(staffElement instanceof StaffChord || staffElement instanceof StaffScale){
                    this.highlightMouseOver(ctx2, mouseY, staffElement.getNotes[0], staffElement, clef);
                }

                if(event instanceof KeyboardEvent){
                    if(event.key === 'x' || event.key === 'X'){
                        this.removeLastNote();
                    } else if(event.key === 'c' || event.key === 'C'){
                        this.removeAllNotes();
                    } else if(event.key === 'w' || event.key === 'W'){
                        this.currPitch = 1;
                    } else if(event.key === 's' || event.key === 'S'){
                        this.currPitch = 2;
                    } else if(event.key === 'a' || event.key === 'A'){
                        this.currPitch = -1;
                    } else if(event.key === 'd' || event.key === 'D'){
                        this.currPitch = -2;
                    } else if(event.key === 'q' || event.key === 'Q'){
                        this.currPitch = 0;
                    }

                    ctx2.clearRect(0, 0, canvas.width, canvas.height);
                }

                this.drawCoreStaffElements(ctx2, clef);
                if(staffElement instanceof StaffInterval){
                    this.highlightMouseOver(ctx2, mouseY, staffElement.getNote1, staffElement, clef);
                } else if(staffElement instanceof StaffChord || staffElement instanceof StaffScale){
                    this.highlightMouseOver(ctx2, mouseY, staffElement.getNotes[0], staffElement, clef);
                }

                if(this.context != null){
                    this.context.clearRect(0, 0, canvas.width, canvas.height);
                    this.context.drawImage(canvas2, 0, 0);
                } else {
                    throw Error("Context failed to initialize");
                }
                   
            } else {
                throw Error("Secondary context failed to initialize");
            }
    
        } else{
            return;
        }
        

    }


    //Event listener for the interval building click event
    public setClickEventListener(clef: boolean, staffElement: any, mouseY: number): void{
        if(this.clickEnabled){
            const canvas2: HTMLCanvasElement = document.createElement('canvas');
            canvas2.width = this.canvas.width;
            canvas2.height = this.canvas.height;
            const ctx2: CanvasRenderingContext2D = canvas2.getContext('2d') as CanvasRenderingContext2D;
            if(ctx2 != null){
                //Set the new staff element on the canvas
                if(staffElement instanceof StaffInterval){
                    this.setNoteOnStaff(mouseY, clef, staffElement.getNote1.getYPos, ctx2);
                } else if(staffElement instanceof StaffChord || staffElement instanceof StaffScale){
                    this.setNoteOnStaff(mouseY, clef, staffElement.getNotes[0].getYPos, ctx2);
                }
                
                if(this.context != null){ 
                    this.context.drawImage(canvas2, 0, 0);
                }
            } else{
                throw Error("Secondary context failed to initialize");
            }

        } else{
            return;
        }
        
        

    }

    //Draws the staff lines
    private drawStaffLines(context: CanvasRenderingContext2D): void{
        context.fillStyle = 'black'; 
        const x: number = this.canvas.width / 1000;
        const width: number = this.canvas.width;
        const height: number = this.canvas.height / 150;

        //Width and height of vertical bar
        const vertBarW: number = this.canvas.width / 40;
        const vertBarH: number = this.canvas.height / 4;
        context.translate(0.5, 0.5);
        context.fillRect(x, this.line1Y, width, height);
        context.fillRect(x, this.line2Y, width, height);
        context.fillRect(x, this.line3Y, width, height);
        context.fillRect(x, this.line4Y, width, height);
        context.fillRect(x, this.line5Y, width, height);
        context.fillRect(x, this.line1Y, vertBarW, vertBarH);
        context.translate(-0.5, -0.5);

        
    }

    //Draws treble clef image
    private drawTrebleClef(context: CanvasRenderingContext2D): void{
        const x: number = this.canvas.width / 100 - this.canvas.width / 50;
        const y: number = (this.canvas.height / 3) - this.canvas.height / 25;
        const w: number = this.canvas.height / 2.5;
        const h: number = this.canvas.height / 2.5;
        if (this.trebleClefImage.complete) {
            // If the image is already loaded, draw it immediately
            context.drawImage(this.trebleClefImage, x, y, w, h);
        } else {
            this.trebleClefImage.onload = () =>{
                context.drawImage(this.trebleClefImage, x, y, w, h);
            }
        }
        
    }

    //Draws bass clef image
    private drawBassClef(context: CanvasRenderingContext2D): void{
        const xPos: number = this.canvas.width / 16;
        const yPos: number = (this.canvas.height / 4) + this.canvas.height / 11.5;
        const width: number = this.canvas.height / 5;
        const height: number = this.canvas.height / 5;
        if (this.bassClefImage.complete) {
            // If the image is already loaded, draw it immediately
            context.drawImage(this.bassClefImage, xPos, yPos, width, height);
        } else {
            this.bassClefImage.onload = () =>{
                context.drawImage(this.bassClefImage, xPos, yPos, width, height);
            }
        }
    }

    //Draws the clef, staff lines, and refreshed notes to the canvas
    public drawCoreStaffElements(context: CanvasRenderingContext2D, clef: boolean): void{
        context.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.drawStaffLines(context);
        this.refreshNotes(context);
        if(clef){
            this.drawTrebleClef(context);
        } else{
            this.drawBassClef(context);
        }
    }

    //Refreshes the notes for the note game
    public refreshNotes(context: CanvasRenderingContext2D): void{
        let noteDisplacementX: number = 0;
        const noteDisplacementY: number = this.canvas.height / 8;

        let sharpDisplacementX: number = 0;
        let flatDisplacementX: number = 0;
        let doubleSharpDisplacementX: number = 0;
        let doubleFlatDisplacementX: number = 0;

        let sharpDisplacementY: number = 0;
        let flatDisplacementY: number = 0;
        let doubleSharpDisplacementY: number = 0;
        let doubleFlatDisplacementY: number = 0;

        let symbolDisplacement: number = 0;

        let x: number = this.canvas.width / 6; 
        let isNeighboringSymbol: boolean = false;
        let isNeighboringNote: boolean = false;
        let noteCount: number = 0;
        for (let i = 0; i < this.drawnNotes.length; i++) {
            //Draws the note onto the canvas
            const staffNote: StaffNote = this.drawnNotes[i];
            const root: string = staffNote.getNote.getRoot;

            //Draw inverted root note for interval games
            if(this.gameId === GameId.StaffBuilding_Intervals || this.gameId === GameId.StaffIdentification_Intervals){
                context.font = this.blackNoteFont;
                if(i === 0){
                    this.drawImage(this.blackNoteImage_rotated, context, this.noteDisplacementX, staffNote.getYPos + noteDisplacementY);
                } else {
                    //If the distance between the neighboring notes is less than half an interval, modify the X displacement
                    const lastNote: StaffNote = this.drawnNotes[i - 1];
                    const lastNoteRoot: string = lastNote.getNote.getRoot;
                    const distance: number = Math.abs(staffNote.getYPos - lastNote.getYPos);
                    if(distance <= this.yIntervalHalf){
                        noteDisplacementX = this.displacementX;
                    }
                    
                    if((distance <= (4 * this.yIntervalHalf) && StaffUtil.isFlat(lastNoteRoot)) || (distance <= (3 * this.yIntervalHalf) && StaffUtil.isSharp(lastNoteRoot))){
                        symbolDisplacement = this.displacementX;
                    }
                    
                    this.drawImage(this.blackNoteImage, context, this.noteDisplacementX + noteDisplacementX, staffNote.getYPos);
                }
                this.drawExtendedLines(staffNote, context, this.shortLineX);

                //Draw notes for chord game
            } else if(this.gameId === GameId.StaffBuilding_Chords || this.gameId === GameId.StaffIdentification_Chords){        
                if(i === 0){
                    if(StaffUtil.isPitchModified(root)){
                        this.currentDisplacementX = this.displacementX;
                    } else {
                        this.currentDisplacementX = 0;
                    }
                    this.drawImage(this.blackNoteImage, context, this.noteDisplacementX + noteDisplacementX, staffNote.getYPos);
                } else {
                    //If the distance between the neighboring notes is half a line interval, modify the X displacement
                    const lastNote: StaffNote = this.drawnNotes[i - 1];
                    const lastNoteRoot: string = lastNote.getNote.getRoot;
                    const distance: number = Math.abs(staffNote.getYPos - lastNote.getYPos);
                    if(distance <= 10 && noteCount % 2 === 0){
                        noteDisplacementX = this.displacementX;
                        isNeighboringNote = !isNeighboringNote;
                    }
                    noteCount++;

                    if(distance > this.yIntervalHalf * 4){
                        symbolDisplacement = 0;
                        this.currentDisplacementX = 0;

                    } else if(isNeighboringSymbol){ 
                        symbolDisplacement = 0;
                        this.currentDisplacementX = 0;
                        isNeighboringSymbol = !isNeighboringSymbol;
                    }
                    else{
                        if(StaffUtil.isPitchModified(lastNoteRoot)){
                            symbolDisplacement = this.displacementX;
                            this.currentDisplacementX = this.displacementX;
                            isNeighboringSymbol = !isNeighboringSymbol;
                        } 
                    }

                    this.drawImage(this.blackNoteImage, context, this.noteDisplacementX + noteDisplacementX, staffNote.getYPos);
                }
                this.drawExtendedLines(staffNote, context, this.shortLineX);
                
            //Draw notes for scale game
            } else if(this.gameId === GameId.StaffBuilding_Scales || this.gameId === GameId.StaffIdentification_Scales){
                x = this.getXScalarWithIndex(i);
                this.drawScaleNote(context, x, staffNote);
            } else if(this.gameId === GameId.StaffIdentification_Notes){
               
                this.drawImage(this.blackNoteImage, context, this.noteDisplacementX, staffNote.getYPos );
                this.drawExtendedLines(staffNote, context, this.shortLineX);
            }

            //Calculate displacement values for scale game
            if(this.gameId === GameId.StaffBuilding_Scales || this.gameId === GameId.StaffIdentification_Scales){
                sharpDisplacementX = x - this.sharpDisplacementX;// - 5;
                flatDisplacementX = x - this.flatDisplacementX;// - 5;
                doubleSharpDisplacementX = x - this.doubleSharpDisplacementX;
                doubleFlatDisplacementX = x - this.doubleFlatDisplacementX;
            } else{
                sharpDisplacementX = this.noteDisplacementX - this.sharpDisplacementX - symbolDisplacement;
                flatDisplacementX = this.noteDisplacementX - this.flatDisplacementX - symbolDisplacement;
                doubleSharpDisplacementX = this.noteDisplacementX - this.doubleSharpDisplacementX - symbolDisplacement;
                doubleFlatDisplacementX = this.noteDisplacementX - this.doubleFlatDisplacementX - symbolDisplacement;
            }

            sharpDisplacementY = staffNote.getYPos + this.sharpDisplacementY;
            flatDisplacementY = staffNote.getYPos + this.flatDisplacementY;
            doubleSharpDisplacementY = staffNote.getYPos + this.doubleSharpDisplacementY;
            doubleFlatDisplacementY = staffNote.getYPos + this.doubleFlatDisplacementY;

            
            if(StaffUtil.isFlat(root)){
                context.font = this.flatFont;
                context.fillText(this.flatSymbol, flatDisplacementX, flatDisplacementY);
            } else if(StaffUtil.isSharp(root)){
                context.font = this.sharpFont;
                context.fillText(this.sharpSymbol, sharpDisplacementX, sharpDisplacementY);
            } else if(StaffUtil.isDoubleFlat(root)){
                context.font = this.doubleFlatFont;
                context.fillText(this.doubleFlatSymbol, doubleFlatDisplacementX, doubleFlatDisplacementY);
            }  else if(StaffUtil.isDoubleSharp(root)){
                context.font = this.doubleSharpFont;
                context.fillText(this.doubleSharpSymbol, doubleSharpDisplacementX, doubleSharpDisplacementY);
            } 
            noteDisplacementX = 0;
        }
    }

    //Draws the scale note 
    private drawScaleNote(context: CanvasRenderingContext2D, x: number, staffNote: StaffNote, fontColor?: string): void{
        context.font = this.wholeNoteFont;

        //restore the context fill style to black before drawing the lines
        context.fillStyle = 'black';
        this.drawExtendedLines(staffNote, context, x - this.canvas.width / 16);

        if(fontColor != null){
            context.fillStyle = fontColor;
        }

        context.save();
        context.translate(x, staffNote.getYPos);
        context.rotate(175 * Math.PI / 180);
        context.fillText(this.wholeNoteSymbol, 0, -(this.canvas.height / 5.6)); 
        context.restore();

    
    }

    //Removes the last note from the canvas, with the exception of the first note
    public removeLastNote(): void{
        if(this.drawnNotes.length > 1){
            this.drawnNotes.pop();
            this.noteCount--;
        }
    }

    //Removes all of the notes from the canvas, with the exception of the first note
    public removeAllNotes(): void{
        if(this.drawnNotes.length > 1){
            for(let i = this.drawnNotes.length - 1; i > 0; i--){
                this.drawnNotes.pop();
                this.noteCount--;
            }
        }
    }

    private redrawStaff(clef: boolean): void{
        //Draw the staff after populating the note array
        const canvas2: HTMLCanvasElement = document.createElement('canvas');
        canvas2.width = this.canvas.width;
        canvas2.height = this.canvas.height;
        const ctx2: CanvasRenderingContext2D = canvas2.getContext('2d') as CanvasRenderingContext2D;
        if(ctx2 != null){
            this.refreshNotes(ctx2);
            this.drawStaffLines(ctx2);
            
            if(clef){
                this.drawTrebleClef(ctx2);
            } else{
                this.drawBassClef(ctx2);
            }
        }

        if(this.context != null){
            this.context.drawImage(canvas2, 0, 0);
        }
    }

    //Draws extended staff lines
    private drawExtendedLines(note: StaffNote, context: CanvasRenderingContext2D, x: number): void{

        //Get abbreviated note name
        const clef: boolean = note.getNote.getClef;
        const fullName: string = note.getFullName;
        const root: string = fullName[0];
        const octave: string = fullName[fullName.length - 1];
        const val: string = root + octave;

        //Push x over just a tad
        if(this.gameId === GameId.StaffBuilding_Scales || this.gameId === GameId.StaffIdentification_Scales){
            x += this.canvas.width / 150;
        } else {
            x += this.canvas.width / 250;
        }
        
        //See if extended lines are necessary for note argument
        if(clef){   
            if(["F3"].includes(val)){
                context.fillRect(x, this.lineBelow1Y, this.shortLineWidth, this.shortLineHeight);
                context.fillRect(x, this.lineBelow2Y, this.shortLineWidth, this.shortLineHeight);
                context.fillRect(x, this.lineBelow3Y, this.shortLineWidth, this.shortLineHeight);

            } 
            else if(["G3", "A3"].includes(val)){
                context.fillRect(x, this.lineBelow1Y, this.shortLineWidth, this.shortLineHeight);
                context.fillRect(x, this.lineBelow2Y, this.shortLineWidth, this.shortLineHeight);
            } 
            else if(["B3", "C4"].includes(val)){
                context.fillRect(x, this.lineBelow1Y, this.shortLineWidth, this.shortLineHeight);
            } 
            else if(["A5", "B5"].includes(val)){
                context.fillRect(x, this.lineAbove1Y, this.shortLineWidth, this.shortLineHeight);
            } 
            else if(["C6", "D6"].includes(val)){
                context.fillRect(x, this.lineAbove2Y, this.shortLineWidth, this.shortLineHeight);
                context.fillRect(x, this.lineAbove1Y, this.shortLineWidth, this.shortLineHeight);
            } 
            else if(["E6"].includes(val)){
                context.fillRect(x, this.lineAbove3Y, this.shortLineWidth, this.shortLineHeight);
                context.fillRect(x, this.lineAbove2Y, this.shortLineWidth, this.shortLineHeight);
                context.fillRect(x, this.lineAbove1Y, this.shortLineWidth, this.shortLineHeight);
            }
        } else{
            if(["A1"].includes(val)){
                context.fillRect(x, this.lineBelow1Y, this.shortLineWidth, this.shortLineHeight);
                context.fillRect(x, this.lineBelow2Y, this.shortLineWidth, this.shortLineHeight);
                context.fillRect(x, this.lineBelow3Y, this.shortLineWidth, this.shortLineHeight);

            } 
            else if(["B1", "C2"].includes(val)){
                context.fillRect(x, this.lineBelow1Y, this.shortLineWidth, this.shortLineHeight);
                context.fillRect(x, this.lineBelow2Y, this.shortLineWidth, this.shortLineHeight);
            } 
            else if(["D2", "E2"].includes(val)){
                context.fillRect(x, this.lineBelow1Y, this.shortLineWidth, this.shortLineHeight);
            } 
            else if(["C4", "D4"].includes(val)){
                context.fillRect(x, this.lineAbove1Y, this.shortLineWidth, this.shortLineHeight);
            } 
            else if(["E4", "F4"].includes(val)){
                context.fillRect(x, this.lineAbove2Y, this.shortLineWidth, this.shortLineHeight);
                context.fillRect(x, this.lineAbove1Y, this.shortLineWidth, this.shortLineHeight);
            } 
            else if(["G4"].includes(val)){
                context.fillRect(x, this.lineAbove3Y, this.shortLineWidth, this.shortLineHeight);
                context.fillRect(x, this.lineAbove2Y, this.shortLineWidth, this.shortLineHeight);
                context.fillRect(x, this.lineAbove1Y, this.shortLineWidth, this.shortLineHeight);
            }
        }
    }

    //Adds gray note over canvas position where mouse is hovering
    private highlightMouseOver(context: CanvasRenderingContext2D, mouseY: number, note: StaffNote, element: StaffElement, clef: boolean): void{                
        const yPos: number = this.drawnNotes[this.drawnNotes.length - 1].getYPos;
        //Determine note depending on mouse position
        const newNote = this.getStaffNoteFromMousePosition(mouseY, clef, context);

        //Draw a grey note in appropriate location
        let isOutOfBounds: boolean = this.checkForNoteIsOutOfBounds(newNote.getYPos, yPos);
        let noteIsTooLow: boolean = this.checkForNoteIsBelow(newNote.getYPos, yPos);
        // noteIsTooLow = false;

        //Before calculating note placement, adjust symbol displacement accordingly
        let symbolDisplacementX: number = 0;
        let noteDisplacementX: number = 0;
        // console.log("VALUE OF " + this.drawnNotes[this.drawnNotes.length - 1].getName + " IS " + this.drawnNotes[this.drawnNotes.length - 1].getYPos);
        // console.log("VALUE OF " + newNote.getFullName + " IS " + newNote.getYPos);
        const distance: number = Math.abs(this.drawnNotes[this.drawnNotes.length - 1].getYPos - newNote.getYPos);
        // console.log("DISTANCE IS " + distance + " AND IS IT LESS THAN 2 Y INTERVALS?" + (distance <= this.yInterval * 2));
        if(element instanceof StaffChord){
            if(distance > this.yInterval * 2){
                symbolDisplacementX = 0;
                this.currentDisplacementX = 0;

            } else {
                const root1: string = this.drawnNotes[this.drawnNotes.length - 1].getNote.getRoot;
                let root2: string = "";
                if(this.drawnNotes.length >= 2){
                    root2 = this.drawnNotes[this.drawnNotes.length - 2].getNote.getRoot;

                    if(this.currentDisplacementX === this.displacementX){
                        symbolDisplacementX = 0;
                    } else {
                        symbolDisplacementX = this.displacementX;
                    }

                    //Always set to 0 if both roots are unmodified
                    if(!StaffUtil.isPitchModified(root1) && !StaffUtil.isPitchModified(root2)){
                        symbolDisplacementX = 0;
                    }

                    //Always set to 0 if the first root is unmodified
                    if(!StaffUtil.isPitchModified(root1) && StaffUtil.isPitchModified(root2)){
                        symbolDisplacementX = 0;
                    }
                        

                } else {
                    if(StaffUtil.isPitchModified(root1)){
                        symbolDisplacementX = this.displacementX;
                        this.currentDisplacementX = this.displacementX;
                    } else{
                        symbolDisplacementX = 0;
                        this.currentDisplacementX = 0;
                    }
                }
            }
            this.currentDisplacementX = symbolDisplacementX;
        } else if(element instanceof StaffInterval){
            symbolDisplacementX = this.displaceIntervalSymbol(note.getNote.getRoot, distance);
            const intervalDistance: number = parseFloat((Math.abs(newNote.getYPos - yPos).toFixed(3)));
            const yInterval: number = parseFloat(this.yInterval.toFixed(3));
            if(intervalDistance < yInterval){
                noteDisplacementX = this.displacementX;
            }
        }
        
        

        //Set the symbol displacement on the x axis
        let sharpDisplacementX: number = this.noteDisplacementX - this.sharpDisplacementX - symbolDisplacementX;
        let flatDisplacementX: number = this.noteDisplacementX - this.flatDisplacementX - symbolDisplacementX;
        let doubleSharpDisplacementX: number = this.noteDisplacementX - this.doubleSharpDisplacementX - symbolDisplacementX;
        let doubleFlatDisplacementX: number = this.noteDisplacementX - this.doubleFlatDisplacementX - symbolDisplacementX;
        if(this.gameId === GameId.StaffBuilding_Scales){
            const x: number = this.getXScalar();
            const fontColor: string = isOutOfBounds ? 'red' : 'grey';
            if(!noteIsTooLow) this.drawScaleNote(context, x, newNote, fontColor);


            sharpDisplacementX = x - this.sharpDisplacementX;
            flatDisplacementX = x - this.flatDisplacementX;
            doubleSharpDisplacementX = x - this.doubleSharpDisplacementX;
            doubleFlatDisplacementX = x - this.doubleFlatDisplacementX;
        }
        
        //Set the symbol displacement on the y axis
        const sharpDisplacementY: number = newNote.getYPos + this.sharpDisplacementY;
        const flatDisplacementY: number = newNote.getYPos + this.flatDisplacementY;
        const doubleSharpDisplacementY: number = newNote.getYPos + this.doubleSharpDisplacementY;
        const doubleFlatDisplacementY: number = newNote.getYPos + this.doubleFlatDisplacementY;
    

        //Offset x position of note when neighboring
        if(!noteIsTooLow){
            context.fillStyle = isOutOfBounds ? 'red' : 'grey';
            if(this.currPitch === 1){
                context.font = this.sharpFont;
                context.fillText(this.sharpSymbol, sharpDisplacementX, sharpDisplacementY);
            } else if(this.currPitch === 2){
                context.font = this.doubleSharpFont;
                context.fillText(this.doubleSharpSymbol, doubleSharpDisplacementX, doubleSharpDisplacementY);
            } else if(this.currPitch === -1){
                context.font = this.flatFont;
                context.fillText(this.flatSymbol, flatDisplacementX, flatDisplacementY);
            } else if(this.currPitch === -2){
                context.font = this.doubleFlatFont;
                context.fillText(this.doubleFlatSymbol, doubleFlatDisplacementX, doubleFlatDisplacementY);
            }

            //context.font = this.blackNoteFont;
            //context.fillText(this.blackNoteSymbol, this.shortLineX + noteDisplacementX, newNote.getYPos);
            if(element instanceof StaffInterval || element instanceof StaffChord){
                let image: HTMLImageElement = isOutOfBounds ? this.redNoteImage : this.greyNoteImage;
                this.drawImage(image, context, this.shortLineX + noteDisplacementX, newNote.getYPos);
                context.fillStyle = 'black';
            }

        }

    }

    //Places a note on the staff
    public setNoteOnStaff(mouseY: number, clef: boolean, noteY: number, context: CanvasRenderingContext2D): void{        
        const yPos: number = this.drawnNotes[this.drawnNotes.length - 1].getYPos;

        //Create the staff note based on coordiante positioning
        const staffNote: StaffNote = this.getStaffNoteFromMousePosition(mouseY, clef, context);
        this.offset = 3;

        const isOutOfBounds: boolean = this.checkForNoteIsOutOfBounds(staffNote.getYPos, yPos);
        const noteIsTooLow: boolean = this.checkForNoteIsBelow(staffNote.getYPos, yPos);
        if(isOutOfBounds){ 
            let image: HTMLImageElement = this.redNoteImage
            this.drawCoreStaffElements(context, clef);
            let x: number = this.shortLineX;
            if(this.gameId === GameId.StaffBuilding_Scales){
                x = this.getXScalar();
                this.drawScaleNote(context, x, staffNote, 'red');
            } else{
                this.drawImage(image, context, this.shortLineX, staffNote.getYPos);
            }
            return;
        } else if(noteIsTooLow){
            this.drawCoreStaffElements(context, clef);
            return;
        }
        //Update the total note count
        this.noteCount++;

        //Check that a note was populated, otherwise do not push it to drawn notes
        if(staffNote.getFullName != "A0"){
            this.drawnNotes.push(staffNote);
        }

        this.refreshNotes(context);
        this.drawCoreStaffElements(context, clef);

    }

    //Checks to see if a note is out of bounds
    private checkForNoteIsOutOfBounds(newNoteY: number, yPos: number): boolean{
        newNoteY = parseFloat(newNoteY.toFixed(3));
        yPos -= this.yInterval;
        yPos = parseFloat(yPos.toFixed(3));
        if((this.gameId === GameId.StaffBuilding_Chords || this.gameId === GameId.StaffBuilding_Scales) && newNoteY < yPos) return true;
        return false;
    }

    //Checks if a note is invalid
    private checkForNoteIsBelow(newNoteY: number, yPos: number): boolean{
        newNoteY = parseFloat(newNoteY.toFixed(3));
        let chordYPos: number = (yPos - this.yIntervalHalf);
        chordYPos = parseFloat(chordYPos.toFixed(3));

        if(this.gameId === GameId.StaffBuilding_Chords){
            if(this.isMobile){
                let chordYPos: number = (yPos - this.yInterval);
                chordYPos = parseFloat(chordYPos.toFixed(3));
                if(newNoteY > chordYPos) return true;
                return false;
            } else {
                if(newNoteY >= chordYPos) return true;
                return false;
            }
        }
        // if(this.gameId === GameId.StaffBuilding_Chords && (newNoteY >= chordYPos)) return true;
        if((this.gameId === GameId.StaffBuilding_Intervals || this.gameId === GameId.StaffBuilding_Scales) && (newNoteY >= yPos)) return true;
        return false;
    }

    //Handles the click event and returns a response to the game handler
    public evaluateNoteSelection(gameId: GameId, clef: boolean): StaffElement{
        let res: StaffElement  = new StaffNote(new Note("A0", true), this.canvas);
        if(this.drawnNotes.length > 1){
            if(gameId === GameId.StaffBuilding_Intervals){
                const note1: Note = this.drawnNotes[0].getNote;
                const note2: Note = this.drawnNotes[1].getNote;
                let interval: Interval = new Interval(note1, 0, clef, note2);
                let staffInterval: StaffInterval = new StaffInterval(interval, this.canvas);
                res = staffInterval;
            } else if(gameId === GameId.StaffBuilding_Chords){
                let intervals: number[] = [];
                for(let i = 0; i < this.drawnNotes.length - 1; i++){
                    const note1: Note = this.drawnNotes[i].getNote;
                    const note2: Note = this.drawnNotes[i+1].getNote;
                    const interval: Interval = new Interval(note1, 0, clef, note2);
                    intervals.push(interval.getNumberOfSteps());
                }
                const chord: Chord = new Chord(this.drawnNotes[0].getNote, ChordQuality.Null, intervals);
                const staffChord: StaffChord = new StaffChord(chord, this.canvas);
                res = staffChord;
                
            } else if(gameId === GameId.StaffBuilding_Scales) {
                let intervals: number[] = [];
                
                for(let i = 0; i < this.drawnNotes.length - 1; i++){
                    const note1: Note = this.drawnNotes[i].getNote;
                    const note2: Note = this.drawnNotes[i+1].getNote;
                    const interval: Interval = new Interval(note1, 0, clef, note2);
                    intervals.push(interval.getNumberOfSteps());
                }
                const scale: Scale = new Scale(ScaleQuality.NULL, this.drawnNotes[0].getNote, intervals);
                const staffScale: StaffScale = new StaffScale(scale, this.canvas);
                res = staffScale;
            }
        } 

        

        return res;

        
    }

    //If user gets a wrong answer in the game handler, adjust the note array so that they can
    //try again
    public async resetNoteArray(staffElement: StaffElement, isTreble: boolean, mouseY: number): Promise<boolean>{
        // Refresh the buffer
        const canvas2: HTMLCanvasElement = document.createElement('canvas');
        canvas2.width = this.canvas.width;
        canvas2.height = this.canvas.height;
        const ctx2: CanvasRenderingContext2D = canvas2.getContext('2d') as CanvasRenderingContext2D;

        const placeholderNote: StaffNote = this.getStaffNoteFromMousePosition(mouseY, isTreble, ctx2);
        if(ctx2 != null){
            if(staffElement instanceof StaffNote || staffElement instanceof StaffInterval){
                //Redraw the staff for notes and intervals
                let firstNote: StaffNote;
                if(staffElement instanceof StaffInterval){
                    firstNote = staffElement.getNote1;
                }else{
                    firstNote = staffElement as StaffNote;
                }
                this.hoverEnabled, this.clickEnabled = false;
                if(this.context != null){
                    this.drawnNotes = [];
                    await this.configureGame(staffElement);
                    this.drawCoreStaffElements(this.context, isTreble);

                    const distance: number = parseFloat(Math.abs(firstNote.getYPos - placeholderNote.getYPos).toFixed(3));
                    const yIntervalHalf: number = parseFloat(this.yIntervalHalf.toFixed(3));
                    let noteDisplacementX: number = distance <= yIntervalHalf ? this.displacementX : 0;
                    if(!this.checkForNoteIsBelow(placeholderNote.getYPos, firstNote.getYPos)){
                        this.context.drawImage(this.greyNoteImage, this.shortLineX + noteDisplacementX, placeholderNote.getYPos);
                    }
                } else {
                    throw Error("Context failed to initialize");
                }
            } else if(staffElement instanceof StaffChord){
                //Determine the number of notes that are allowed at any given time
                let lowerBound: number = 2;
                if(staffElement.getQuality >= 13 && staffElement.getQuality <= 29){
                    lowerBound = 3;
                } else if(staffElement.getQuality >= 30 && staffElement.getQuality <= 54){
                    lowerBound = 4;
                } else if(staffElement.getQuality >= 55 && staffElement.getQuality <= 74){
                    lowerBound = 5;
                } else if(staffElement.getQuality >= 75){
                    lowerBound = 6;
                }

                //Enforce lower bound value and redraw the staff
                if(staffElement.getNotes.length > lowerBound && this.context != null){
                    const firstNote: StaffNote = staffElement.getNotes[0];
                    const isOutOfBounds: boolean = this.checkForNoteIsOutOfBounds(placeholderNote.getYPos, firstNote.getYPos);

                    let noteImage: HTMLImageElement;
                    if(isOutOfBounds) noteImage = this.redNoteImage;
                    else noteImage = this.greyNoteImage;

                    this.drawnNotes = [];
                    await this.configureGame(staffElement);
                    this.drawCoreStaffElements(this.context, isTreble);


                    if(!this.checkForNoteIsBelow(placeholderNote.getYPos, firstNote.getYPos)){
                        this.context.drawImage(noteImage, this.shortLineX, placeholderNote.getYPos);
                    }

                }
            } else if(staffElement instanceof StaffScale){
                //Redraw the staff for scales
                this.hoverEnabled, this.clickEnabled = false;
                if(this.context != null){
                    const firstNote: StaffNote = staffElement.getNotes[0];

                    this.drawnNotes = [];
                    await this.configureGame(staffElement);
                    this.drawCoreStaffElements(this.context, isTreble);

                
                    if(!this.checkForNoteIsBelow(placeholderNote.getYPos, firstNote.getYPos)){
                        const x: number = this.getXScalar();
                        const isOutOfBounds: boolean = this.checkForNoteIsOutOfBounds(placeholderNote.getYPos, firstNote.getYPos);
                        let fontColor: string = 'grey';
                        if(isOutOfBounds) fontColor = 'red';
                        this.drawScaleNote(this.context, x, placeholderNote, fontColor);
                    }
                } else {
                    throw Error("Context failed to initialize");
                }
    
            }
            this.noteCount = 1;
            
        } else {
            throw Error("Secondary context failed to initialize");
        }
        
        //Reenable hover and click events
        this.hoverEnabled, this.clickEnabled = true;
        return true; 
    }

    //For staff building games, change the note color depending on if the answer is right or wrong
    public changeNoteColor(staffElement: StaffElement, clef: boolean, isCorrect: boolean): void{
        let noteDisplacementX: number = 0;
        let canvas2: HTMLCanvasElement = document.createElement('canvas');

        //Include note displacement for interval elements
        if(staffElement instanceof StaffInterval){
            const y1: number = staffElement.getNote1.getYPos;
            const y2: number = staffElement.getNote2.getYPos;
            const distance: number = parseFloat(Math.abs(y1 - y2).toFixed(3));
            const yIntervalHalf: number = parseFloat(this.yIntervalHalf.toFixed(3));
            // console.log("DISTANCE IS " + distance);
            // console.log("Y INTERVAL HALF IS " + this.yIntervalHalf);
            if(distance <= yIntervalHalf){
                noteDisplacementX = this.displacementX;
            }
        }

        //Draw the new canvas to the buffer
        canvas2 = this.drawColoredNotes(staffElement, noteDisplacementX, isCorrect, clef);        
        if(this.context != null){
            this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
            this.context.drawImage(canvas2, 0, 0);
            // this.context.drawImage(image, this.shortLineX, 250);
        } else{
            throw Error("Context failed to initialize");
        }
     
    }

    //Draws the colored notes and returns the canvas
    private drawColoredNotes(staffElement: StaffElement, noteDisplacementX: number, isCorrect: boolean, isTreble: boolean): HTMLCanvasElement{
        const noteDisplacementY: number = this.canvas.height / 8;

        let noteImage: HTMLImageElement = new Image();
        let rotatedNoteImage: HTMLImageElement = new Image();
        let fontColor: string = 'black';

        //Draw the colored notes to indicate correctness
        if(isCorrect){
            noteImage = this.greenNoteImage;
            rotatedNoteImage = this.greenNoteImage_rotated;
            fontColor = 'green';
        } else {
            noteImage = this.redNoteImage;
            rotatedNoteImage = this.redNoteImage_rotated;
            fontColor = 'red';
        }

        const canvas2: HTMLCanvasElement = document.createElement('canvas');
        canvas2.width = this.canvas.width;
        canvas2.height = this.canvas.height;
        const ctx2: CanvasRenderingContext2D = canvas2.getContext('2d') as CanvasRenderingContext2D;

        if(ctx2 != null){
            this.drawStaffLines(ctx2);
            if(staffElement instanceof StaffInterval){
                this.drawExtendedLines(this.drawnNotes[0], ctx2, this.shortLineX);
                this.drawExtendedLines(this.drawnNotes[1], ctx2, this.shortLineX);
                this.changeSymbolColor(ctx2, fontColor, staffElement, 0);
                this.drawImage(rotatedNoteImage, ctx2, this.noteDisplacementX, this.drawnNotes[0].getYPos + noteDisplacementY);
                this.drawImage(noteImage, ctx2, this.noteDisplacementX + noteDisplacementX, this.drawnNotes[1].getYPos);
            } else if(staffElement instanceof StaffChord){
                this.drawnNotes.forEach((note) => {
                    this.drawExtendedLines(note, ctx2, this.shortLineX);
                    this.drawImage(noteImage, ctx2, this.noteDisplacementX + noteDisplacementX, note.getYPos);
                });
                this.changeSymbolColor(ctx2, fontColor, staffElement, 0);

            } else if(staffElement instanceof StaffScale){
                const temp: StaffNote[] = this.drawnNotes;

                for(let i = 0; i < temp.length; i++){
                    this.drawnNotes = temp.slice(0, i);
                    const staffNote: StaffNote = temp[i];
                    const root: string = staffNote.getNote.getRoot;

                    const x: number = this.getXScalar(); 

                    const sharpDisplacementX: number = x - this.sharpDisplacementX;
                    const flatDisplacementX: number = x - this.flatDisplacementX;
                    const doubleSharpDisplacementX: number = x - this.doubleSharpDisplacementX;
                    const doubleFlatDisplacementX: number = x - this.doubleFlatDisplacementX;

                    const sharpDisplacementY: number = staffNote.getYPos + this.sharpDisplacementY;
                    const flatDisplacementY: number = staffNote.getYPos + this.flatDisplacementY;
                    const doubleSharpDisplacementY: number = staffNote.getYPos + this.doubleSharpDisplacementY;
                    const doubleFlatDisplacementY: number = staffNote.getYPos + this.doubleFlatDisplacementY;
                    // if(i === 0){
                        
                    // }
                    if(StaffUtil.isFlat(root)){
                        ctx2.font = this.flatFont;
                        ctx2.fillText(this.flatSymbol, flatDisplacementX, flatDisplacementY);
                    } else if(StaffUtil.isSharp(root)){
                        ctx2.font = this.sharpFont;
                        ctx2.fillText(this.sharpSymbol, sharpDisplacementX, sharpDisplacementY);
                    } else if(StaffUtil.isDoubleFlat(root)){
                        ctx2.font = this.doubleFlatFont;
                        ctx2.fillText(this.doubleFlatSymbol, doubleFlatDisplacementX, doubleFlatDisplacementY);
                    }  else if(StaffUtil.isDoubleSharp(root)){
                        ctx2.font = this.doubleSharpFont;
                        ctx2.fillText(this.doubleSharpSymbol, doubleSharpDisplacementX, doubleSharpDisplacementY);
                    } 
                    // this.changeSymbolColor(ctx2, fontColor, staffElement, x, i);
                    this.drawScaleNote(ctx2, x, staffNote, fontColor);
                }

                let root: string = this.drawnNotes[0].getNote.getRoot;
                if(StaffUtil.isPitchModified(root)){
                    const x: number = this.xOffset;
                    const staffNote: StaffNote = this.drawnNotes[0];
                    const sharpDisplacementX: number = x - this.sharpDisplacementX;
                    const flatDisplacementX: number = x - this.flatDisplacementX;
                    const doubleSharpDisplacementX: number = x - this.doubleSharpDisplacementX;
                    const doubleFlatDisplacementX: number = x - this.doubleFlatDisplacementX;

                    const sharpDisplacementY: number = staffNote.getYPos + this.sharpDisplacementY;
                    const flatDisplacementY: number = staffNote.getYPos + this.flatDisplacementY;
                    const doubleSharpDisplacementY: number = staffNote.getYPos + this.doubleSharpDisplacementY;
                    const doubleFlatDisplacementY: number = staffNote.getYPos + this.doubleFlatDisplacementY;
                    
                    if(StaffUtil.isFlat(root)){
                        ctx2.font = this.flatFont;
                        ctx2.fillText(this.flatSymbol, flatDisplacementX, flatDisplacementY);
                    } else if(StaffUtil.isSharp(root)){
                        ctx2.font = this.sharpFont;
                        ctx2.fillText(this.sharpSymbol, sharpDisplacementX, sharpDisplacementY);
                    } else if(StaffUtil.isDoubleFlat(root)){
                        ctx2.font = this.doubleFlatFont;
                        ctx2.fillText(this.doubleFlatSymbol, doubleFlatDisplacementX, doubleFlatDisplacementY);
                    }  else if(StaffUtil.isDoubleSharp(root)){
                        ctx2.font = this.doubleSharpFont;
                        ctx2.fillText(this.doubleSharpSymbol, doubleSharpDisplacementX, doubleSharpDisplacementY);
                    } 
                }

            }
            
            if(isTreble){
                this.drawTrebleClef(ctx2);   
            } else{
                this.drawBassClef(ctx2);
            }

        } else {
            throw Error("Secondary context failed to initialize");
        }
        return canvas2;
    }

    //Draws the colored symbols depending on right or wrong selection for staff building games
    private changeSymbolColor(context: CanvasRenderingContext2D, fillColor: string, staffElement: StaffElement, x: number, i?: number): void{
        let symbolDisplacementX: number = 0;
        let sharpDisplacementX: number = 0;
        let flatDisplacementX: number = 0;
        let doubleSharpDisplacementX: number = 0;
        let doubleFlatDisplacementX: number = 0;

        context.fillStyle = fillColor; 

        //Iterate through each drawn note and adjust their symbol colors
        if(staffElement instanceof StaffScale && i != null){
            sharpDisplacementX = x - this.sharpDisplacementX;
            flatDisplacementX = x - this.flatDisplacementX;
            doubleSharpDisplacementX = x - this.doubleSharpDisplacementX;
            doubleFlatDisplacementX = x - this.doubleFlatDisplacementX;
            
            const note: StaffNote = this.drawnNotes[i];
            const root: string = note.getNote.getRoot;
            const y: number = note.getYPos;

            //Draw the subsequent symbols
            if(StaffUtil.isFlat(root)){
                context.font = this.flatFont;
                context.fillText(this.flatSymbol, flatDisplacementX, y + this.flatDisplacementY);
            } else if(StaffUtil.isSharp(root)){
                context.font = this.sharpFont;
                context.fillText(this.sharpSymbol, sharpDisplacementX, y + this.sharpDisplacementY);
            } else if(StaffUtil.isDoubleFlat(root)){
                context.font = this.doubleFlatFont;
                context.fillText(this.doubleFlatSymbol, doubleFlatDisplacementX, y + this.doubleFlatDisplacementY);
            } else if(StaffUtil.isDoubleSharp(root)){
                context.font = this.doubleSharpFont;
                context.fillText(this.doubleSharpSymbol, doubleSharpDisplacementX, y + this.doubleSharpDisplacementY);
            }
        } else {
            for(let i = 0; i < this.drawnNotes.length - 1; i++){
                const note1: StaffNote = this.drawnNotes[i];
                const note2: StaffNote = this.drawnNotes[i + 1];
                const root1: string = note1.getNote.getRoot;
                const root2: string = note2.getNote.getRoot;
                const y1: number = note1.getYPos;
                const y2: number = note2.getYPos;
    
                //Adjust the symbol displacement for the chord games
                if(staffElement instanceof StaffChord){
                    if(StaffUtil.isPitchModified(root1)){
                        symbolDisplacementX = symbolDisplacementX === 0 ? this.displacementX : 0;
                    } else {
                        symbolDisplacementX = 0;
                    }
                } else if(staffElement instanceof StaffInterval){
                    const distance: number = Math.abs(y1 - y2);
                    symbolDisplacementX = this.displaceIntervalSymbol(root1, distance);
                }
    
                //Adjust the individual symbol displacements
                if(this.gameId === GameId.StaffBuilding_Scales || this.gameId === GameId.StaffIdentification_Scales){
                    sharpDisplacementX = x - this.sharpDisplacementX - 5;
                    flatDisplacementX = x - this.flatDisplacementX - 5;
                    doubleSharpDisplacementX = x - this.doubleSharpDisplacementX;
                    doubleFlatDisplacementX = x - this.doubleFlatDisplacementX;
                } else{
                    sharpDisplacementX = this.noteDisplacementX - this.sharpDisplacementX - symbolDisplacementX;
                    flatDisplacementX = this.noteDisplacementX - this.flatDisplacementX - symbolDisplacementX;
                    doubleSharpDisplacementX = this.noteDisplacementX - this.doubleSharpDisplacementX - symbolDisplacementX;
                    doubleFlatDisplacementX = this.noteDisplacementX - this.doubleFlatDisplacementX - symbolDisplacementX;
                }
    
                //Draw the first symbol of the element
                if(i === 0){
                    if(StaffUtil.isFlat(root1)){
                        context.font = this.flatFont;
                        context.fillText(this.flatSymbol, flatDisplacementX + symbolDisplacementX, y1 + this.flatDisplacementY);
                    } else if(StaffUtil.isSharp(root1)){
                        context.font = this.sharpFont;
                        context.fillText(this.sharpSymbol, sharpDisplacementX + symbolDisplacementX, y1 + this.sharpDisplacementY);
                    } else if(StaffUtil.isDoubleFlat(root1)){
                        context.font = this.doubleFlatFont;
                        context.fillText(this.doubleFlatSymbol, doubleFlatDisplacementX + symbolDisplacementX, y1 + this.doubleFlatDisplacementY);
                    } else if(StaffUtil.isDoubleSharp(root1)){
                        context.font = this.doubleSharpFont;
                        context.fillText(this.doubleSharpSymbol, doubleSharpDisplacementX + symbolDisplacementX, y1 + this.doubleSharpDisplacementY);
                    }
                }
    
                //Draw the subsequent symbols
                if(StaffUtil.isFlat(root2)){
                    context.font = this.flatFont;
                    context.fillText(this.flatSymbol, flatDisplacementX, y2 + this.flatDisplacementY);
                } else if(StaffUtil.isSharp(root2)){
                    context.font = this.sharpFont;
                    context.fillText(this.sharpSymbol, sharpDisplacementX, y2 + this.sharpDisplacementY);
                } else if(StaffUtil.isDoubleFlat(root2)){
                    context.font = this.doubleFlatFont;
                    context.fillText(this.doubleFlatSymbol, doubleFlatDisplacementX, y2 + this.doubleFlatDisplacementY);
                } else if(StaffUtil.isDoubleSharp(root2)){
                    context.font = this.doubleSharpFont;
                    context.fillText(this.doubleSharpSymbol, doubleSharpDisplacementX, y2 + this.doubleSharpDisplacementY);
                }
                
            }

        }
    }
    
    public drawImage(image: HTMLImageElement, context: CanvasRenderingContext2D, x: number, y: number): void{       
        if (image.complete) {
            // If the image is already loaded, draw it immediately
            context.drawImage(image, x, y);
            
        } else {
            // If the image is not loaded yet, set the onload event
            image.onload = () => {
                context.drawImage(image, x, y);
            };
        }

    }

    public drawRotatedImage(image: HTMLImageElement, context: CanvasRenderingContext2D, x: number, y: number): void{       
        context.save();
        if (image.complete) {
            // If the image is already loaded, draw it immediately
            context.translate(200, 200);
            context.rotate(180 * (Math.PI/180)); 
            context.drawImage(image, x, y);
            
        } else {
            // If the image is not loaded yet, set the onload event
            image.onload = () => {
                context.translate(200, 200);
                context.rotate(180 * (Math.PI/180)); 
                context.drawImage(image, x, y);
            };
        }
        context.restore();

    }

    get getDrawnNotes(): StaffNote[]{
        return this.drawnNotes;
    }

    //Get the appropriate note name depending on the location of the mouse Y coordinate
    public getStaffNoteFromMousePosition(mouseY: number, clef: boolean, context: CanvasRenderingContext2D): StaffNote{
        const lineDisplacementX: number = this.shortLineX + this.getExtendedLinesXDisplacement();
        let newNoteName: string = "A0";
        let noteYOffset: number = this.noteYOffset;
        if(this.gameId === GameId.StaffBuilding_Scales){
            noteYOffset /= 2;
        }

        if(mouseY < ((this.lineBelow3YThreshold + this.lineBelow2YThreshold) /2 )  + noteYOffset && mouseY > ((this.lineBelow3YThreshold + this.lineBelow2YThreshold)/2 )  - noteYOffset){
            if(clef) newNoteName = "G3";
            else newNoteName = "B1";
        } else if(mouseY < this.lineBelow2YThreshold  + noteYOffset && mouseY > this.lineBelow2YThreshold  - noteYOffset){
            if(clef) newNoteName = "A3";
            else newNoteName = "C2";
        } else if(mouseY < ((this.lineBelow2YThreshold + this.lineBelow1YThreshold)/2 )  + noteYOffset && mouseY > ((this.lineBelow2YThreshold + this.lineBelow1YThreshold)/2 )  - noteYOffset){
            if(clef) newNoteName = "B3";
            else newNoteName = "D2";
        } else if(mouseY < this.lineBelow1YThreshold  + noteYOffset && mouseY > this.lineBelow1YThreshold  - noteYOffset){
            if(clef) newNoteName = "C4";
            else newNoteName = "E2";
        } else if(mouseY < ((this.lineBelow1YThreshold + this.line5YThreshold)/2 )  + noteYOffset && mouseY > ((this.lineBelow1YThreshold + this.line5YThreshold)/2 )  - noteYOffset){
            if(clef) newNoteName = "D4";
            else newNoteName = "F2";
        } else if(mouseY < this.line5YThreshold  + noteYOffset && mouseY > this.line5YThreshold  - noteYOffset){
            if(clef) newNoteName = "E4";
            else newNoteName = "G2";
        } else if(mouseY < ((this.line5YThreshold + this.line4YThreshold)/2 )  + noteYOffset && mouseY > ((this.line5YThreshold + this.line4YThreshold)/2 )  - noteYOffset){
            if(clef) newNoteName = "F4";
            else newNoteName = "A2";
        } else if(mouseY < this.line4YThreshold  + noteYOffset && mouseY > this.line4YThreshold  - noteYOffset){
            if(clef) newNoteName = "G4";
            else newNoteName = "B2";
        } else if(mouseY < ((this.line4YThreshold + this.line3YThreshold)/2 )  + noteYOffset && mouseY > ((this.line4YThreshold + this.line3YThreshold)/2 )  - noteYOffset){
            if(clef) newNoteName = "A4";
            else newNoteName = "C3";
        } else if(mouseY < this.line3YThreshold + noteYOffset && mouseY > this.line3YThreshold - noteYOffset){
            if(clef) newNoteName = "B4";
            else newNoteName = "D3";
        } else if(mouseY < ((this.line3YThreshold + this.line2YThreshold)/2 )  + noteYOffset && mouseY > ((this.line3YThreshold + this.line2YThreshold)/2 )  - noteYOffset){
            if(clef) newNoteName = "C5";
            else newNoteName = "E3";
        } else if(mouseY < this.line2YThreshold + noteYOffset && mouseY > this.line2YThreshold  - noteYOffset){
            if(clef) newNoteName = "D5";
            else newNoteName = "F3";
        } else if(mouseY < ((this.line2YThreshold + this.line1YThreshold)/2 )  + noteYOffset && mouseY > ((this.line2YThreshold + this.line1YThreshold)/2 )  - noteYOffset){
            if(clef) newNoteName = "E5";
            else newNoteName = "G3";
        } else if(mouseY < this.line1YThreshold  + noteYOffset && mouseY > this.line1YThreshold  - noteYOffset){
            if(clef) newNoteName = "F5";
            else newNoteName = "A3";
        } else if(mouseY < ((this.line1YThreshold + this.lineAbove1YThreshold)/2 )  + noteYOffset && mouseY > ((this.line1YThreshold + this.lineAbove1YThreshold)/2 )  - noteYOffset){
            if(clef) newNoteName = "G5";
            else newNoteName = "B3";
        } else if(mouseY < this.lineAbove1YThreshold  + noteYOffset && mouseY > this.lineAbove1YThreshold  - noteYOffset){
            if(clef) newNoteName = "A5";
            else newNoteName = "C4";

            //Fill in extended lines
            if(this.gameId !== GameId.StaffBuilding_Scales){
                context.fillRect(lineDisplacementX, this.lineAbove1Y, this.shortLineWidth, this.shortLineHeight);
            }
        } else if(mouseY < ((this.lineAbove1YThreshold + this.lineAbove2YThreshold)/2 )  + noteYOffset && mouseY > ((this.lineAbove1YThreshold + this.lineAbove2YThreshold)/2 )  - noteYOffset){
            if(clef) newNoteName = "B5";
            else newNoteName = "D4";        

            //Fill in extended lines
            if(this.gameId !== GameId.StaffBuilding_Scales){
                context.fillRect(lineDisplacementX, this.lineAbove1Y, this.shortLineWidth, this.shortLineHeight);
            }
        } else if(mouseY < this.lineAbove2YThreshold + noteYOffset && mouseY > this.lineAbove2YThreshold - noteYOffset){
            if(clef) newNoteName = "C6";
            else newNoteName = "E4";

            //Fill in extended lines
            if(this.gameId !== GameId.StaffBuilding_Scales){
                context.fillRect(lineDisplacementX, this.lineAbove2Y, this.shortLineWidth, this.shortLineHeight);
                context.fillRect(lineDisplacementX, this.lineAbove1Y, this.shortLineWidth, this.shortLineHeight);
            }
        } else if(mouseY < ((this.lineAbove2YThreshold + this.lineAbove3YThreshold)/2 )  + noteYOffset && mouseY > ((this.lineAbove2YThreshold + this.lineAbove3YThreshold)/2 )  - noteYOffset){
            if(clef) newNoteName = "D6";
            else newNoteName = "F4";   
            
            //Fill in extended lines
            if(this.gameId !== GameId.StaffBuilding_Scales){
                context.fillRect(lineDisplacementX, this.lineAbove2Y, this.shortLineWidth, this.shortLineHeight);
                context.fillRect(lineDisplacementX, this.lineAbove1Y, this.shortLineWidth, this.shortLineHeight);
            }
        } else if(mouseY < this.lineAbove3YThreshold  + noteYOffset && mouseY > this.lineAbove3YThreshold  - noteYOffset){
            if(clef) newNoteName = "E6";
            else newNoteName = "G4";

            //Fill in extended lines
            if(this.gameId !== GameId.StaffBuilding_Scales){
                context.fillRect(lineDisplacementX, this.lineAbove3Y, this.shortLineWidth, this.shortLineHeight);
                context.fillRect(lineDisplacementX, this.lineAbove2Y, this.shortLineWidth, this.shortLineHeight);
                context.fillRect(lineDisplacementX, this.lineAbove1Y, this.shortLineWidth, this.shortLineHeight);
            }
        }

        //Select the symbol if one is present
        let symbol: string = "";
        switch(this.currPitch){
            case -2:
                symbol = "bb";
                break;
            case -1:
                symbol = "b";
                break;
            case 0:
                symbol = "";
                break;
            case 1: 
                symbol = "#";
                break;
            case 2:
                symbol = "x";
                break;
        } 

        //Add symbol between note root and octave number if present
        if(!CommonUtil.isEmpty(symbol as string)){
            newNoteName = newNoteName[0] + symbol + newNoteName[1];
        }

        // console.log("NOTE NAME AT END OF METHOD IS " + newNoteName);


        return new StaffNote(new Note(newNoteName, clef), this.canvas);
    
    }


    //Displace symbol for interval games if notes are too close to one another
    private displaceIntervalSymbol(root: string, distance: number): number{
        let symbolDisplacementX: number = 0;
        if(StaffUtil.isFlat(root) || StaffUtil.isDoubleFlat(root)){
            if(distance > 2 * this.yInterval){
                symbolDisplacementX = 0;
            } else {
                symbolDisplacementX = this.displacementX;
            }
        } else if(StaffUtil.isSharp(root) || StaffUtil.isDoubleSharp(root)){
            if(distance > 2 * this.yInterval){
                symbolDisplacementX = 0;
            } else {
                symbolDisplacementX = this.displacementX;
            }
        }

        return symbolDisplacementX;
    }

    //Returns the x scalar value for scale games
    private getXScalar(): number{
        const n: number = this.drawnNotes.length;
        const x: number = (n * this.xScalar) + this.xOffset;
        return x;
    }

    private getXScalarWithIndex(n: number): number{
        const x: number = (n * this.xScalar) + this.xOffset;
        return x;
    }

    //Gets the x displacement for the extended lines
    private getExtendedLinesXDisplacement(): number{
        if(this.gameId === GameId.StaffBuilding_Scales || this.gameId === GameId.StaffIdentification_Scales){
            return this.canvas.width / 110;
        } else {
            return this.canvas.width / 250;
        }
    }

}