<div class="app-parent-container">
    <div class="main-container margin-top">
        <div class="logo-container margin-top-icon">
            <img src="/assets/icons/BeatTheory-banner.png" />
            <h3><b>Thanks for registering!</b></h3>
        </div>
        <div class="auth-control-container">
            <hr />
            <div class="auth-field-container">
                <p *ngIf="email == ''">A verification email has been sent.  Please use the link in the email to finish registering your BeatTheory account.</p>
                <p *ngIf="email != ''">A verification email has been sent to {{email}}.  Please use the link in the email to finish registering your BeatTheory account.</p>
                <br /> 
                <p>You will not have access to user-exclusive content until your email is registered.</p>
            </div>
            <div class="auth-button-container">
                <button type="submit" (click)="router.navigate(['/'])">Go to Home Page</button>
                <button type="submit" (click)="router.navigate(['/login'])">Log In</button>
            </div>
            <div class="auth-field-container margin-bottom">
                <p *ngIf="!isSendingVerificationEmail" class="new-link"><a (click)="sendNewVerificationEmail()">Resend email verification</a></p>
                <div>
                    <app-spinner *ngIf="isSendingVerificationEmail"></app-spinner>
                </div>
                <p class="auth-error-message" *ngIf="verificationError_emailNotSent">The verification email could not be sent.  Please try again.</p>
                <p class="auth-error-message auth-error-message-left" *ngIf="verificationError_noEmailAvailable">Your email could not be retrieved from the session.  Please try to <a href="/login">log in</a> and request a 
                    new verification email from there.
                </p>
    
            </div>
        </div>
        
    </div>
</div>

