export class Note{
    private root: string;
    private octave: number = 4;
    private frequency: number;
    private fullName: string;
    private clef?: boolean;
    private audioPath: string;

    constructor(fullName: string, clef?: boolean){
        this.fullName = fullName;
        this.clef = clef === undefined ? true: clef;
        const splitNote = this.splitNote(this.fullName);
        if(splitNote){
            this.root = splitNote.root;
            this.octave = splitNote.octave;
        }else{
            this.root = "A";
            this.octave = 0;
        }

        this.frequency = this.determineFrequency();
        this.audioPath = this.determineFilePath();
    }

    get getRoot(): string {
        return this.root;
    }

    set setName(root: string){
        this.root = root
    }

    get getOctave(): number {
        return this.octave;
    }

    set setOctave(octave: number){
        if(!Number.isInteger(octave)){
            throw new Error("[ERROR]: Octave value must be an integer");
        }
        this.octave = octave;
    }

    get getFrequency(): number{
        return this.frequency;
    }

    set setFrequency(frequency: number){
        this.frequency = frequency;
    }

    get getFullName(): string {
        return this.fullName;
    }

    set setFullName(name: string){
        this.fullName = name
    }

    get getAudioPath(): string{
        return this.audioPath;
    }

    set setAudioPath(audioPath: string){
        this.audioPath = audioPath;
    }

    get getClef(): any{
        if(this.clef != undefined){
            return this.clef;
        }
        return undefined;
    }

    set setClef(clef: boolean){
        this.clef = clef;
    }


    //Maps the fully qualified note name to its filepath location
    private determineFilePath(): string{
        let res: string = "";
        switch(this.fullName){
            case "C3":
                res = "assets/notes/C3.mp3";
                break;
            case "C#3":
                res = "assets/notes/Db3.mp3";
                break;
            case "Cx3":
                res = "assets/notes/D3.mp3";
                break;
            case "Dbb3":
                res = "assets/notes/C3.mp3";
                break;
            case "Db3":
                res = "assets/notes/Db3.mp3";
                break;
            case "D3":
                res = "assets/notes/D3.mp3";
                break;
            case "D#3":
                res = "assets/notes/Eb3.mp3";
                break;
            case "Dx3":
                res = "assets/notes/E3.mp3";
                break;
            case "Ebb3":
                res = "assets/notes/D3.mp3";
                break;   
            case "Eb3":
                res = "assets/notes/Eb3.mp3";
                break;
            case "E3":
                res = "assets/notes/E3.mp3";
                break;
            case "E#3":
                res = "assets/notes/F3.mp3";
                break;
            case "Ex3":
                res = "assets/notes/Gb3.mp3";
                break;
            case "Exx3":
                res = "assets/notes/G3.mp3";
                break;
            case "Fbb3":
                res = "assets/notes/Eb3.mp3";
                break;
            case "Fb3":
                res = "assets/notes/E3.mp3";
                break;
            case "F3":
                res = "assets/notes/F3.mp3";
                break;
            case "F#3":
                res = "assets/notes/Gb3.mp3";
                break;
            case "Fx3":
                res = "assets/notes/G3.mp3";
                break;
            case "Gbb3":
                res = "assets/notes/F3.mp3";
                break;
            case "Gb3":
                res = "assets/notes/Gb3.mp3";
                break;
            case "G3":
                res = "assets/notes/G3.mp3";
                break;
            case "G#3":
                res = "assets/notes/Ab3.mp3";
                break;
            case "Gx3":
                res = "assets/notes/A3.mp3";
                break;
            case "Abb3":
                res = "assets/notes/G3.mp3";
                break;
            case "Ab3": 
                res = "assets/notes/Ab3.mp3";
                break;  
            case "A3": 
                res = "assets/notes/A3.mp3";
                break;  
            case "A#3": 
                res = "assets/notes/Bb3.mp3";
                break; 
            case "Ax3": 
                res = "assets/notes/B3.mp3";
                break;
            case "Axx3": 
                res = "assets/notes/C4.mp3";
                break;
            case "Bbb3": 
                res = "assets/notes/A3.mp3";
                break;  
            case "Bb3": 
                res = "assets/notes/Bb3.mp3";
                break;  
            case "B3": 
                res = "assets/notes/B3.mp3";
                break; 
            case "B#3": 
                res = "assets/notes/C4.mp3";
                break;  
            case "Bx3":
                res = "assets/notes/Db4.mp3";
                break;   
            case "Cbb4":
                res = "assets/notes/Bb3.mp3";
                break;     
            case "Cb4":
                res = "assets/notes/B3.mp3";
                break;
            case "C4":
                res = "assets/notes/C4.mp3";
                break;
            case "C#4":
                res = "assets/notes/Db4.mp3";
                break;
            case "Cx4":
                res = "assets/notes/D4.mp3";
                break;
            case "Dbb4":
                res = "assets/notes/C4.mp3";
                break;
            case "Db4":
                res = "assets/notes/Db4.mp3";
                break;
            case "D4":
                res = "assets/notes/D4.mp3";
                break;
            case "D#4":
                res = "assets/notes/Eb4.mp3";
                break;
            case "Dx4":
                res = "assets/notes/E4.mp3";
                break;
            case "Ebb4":
                res = "assets/notes/D4.mp3";
                break;   
            case "Eb4":
                res = "assets/notes/Eb4.mp3";
                break;
            case "E4":
                res = "assets/notes/E4.mp3";
                break;
            case "E#4":
                res = "assets/notes/F4.mp3";
                break;
            case "Ex4":
                res = "assets/notes/Gb4.mp3";
                break;
            case "Exx4":
                res = "assets/notes/G4.mp3";
                break;
            case "Fbb4":
                res = "assets/notes/Eb4.mp3";
                break;
            case "Fb4":
                res = "assets/notes/E4.mp3";
                break;
            case "F4":
                res = "assets/notes/F4.mp3";
                break;
            case "F#4":
                res = "assets/notes/Gb4.mp3";
                break;
            case "Fx4":
                res = "assets/notes/G4.mp3";
                break;
            case "Gbb4":
                res = "assets/notes/F4.mp3";
                break;
            case "Gb4":
                res = "assets/notes/Gb4.mp3";
                break;
            case "G4":
                res = "assets/notes/G4.mp3";
                break;
            case "G#4":
                res = "assets/notes/Ab4.mp3";
                break;
            case "Gx4":
                res = "assets/notes/A4.mp3";
                break;
            case "Abb4":
                res = "assets/notes/G4.mp3";
                break;
            case "Ab4": 
                res = "assets/notes/Ab4.mp3";
                break;  
            case "A4": 
                res = "assets/notes/A4.mp3";
                break;  
            case "A#4": 
                res = "assets/notes/Bb4.mp3";
                break; 
            case "Ax4": 
                res = "assets/notes/B4.mp3";
                break;
            case "Axx4": 
                res = "assets/notes/C5.mp3";
                break;
            case "Bbb4": 
                res = "assets/notes/A4.mp3";
                break;  
            case "Bb4": 
                res = "assets/notes/Bb4.mp3";
                break;  
            case "B4": 
                res = "assets/notes/B4.mp3";
                break; 
            case "B#4": 
                res = "assets/notes/C5.mp3";
                break;  
            case "Bx4":
                res = "assets/notes/Db5.mp3";
                break;   
            case "Cbb5":
                res = "assets/notes/Bb4.mp3";
                break;     
            case "Cb5":
                res = "assets/notes/B4.mp3";
                break;
            case "C5":
                res = "assets/notes/C5.mp3";
                break;
            case "C#5":
                res = "assets/notes/Db5.mp3";
                break;
            case "Cx5":
                res = "assets/notes/D5.mp3";
                break;
            case "Dbb5":
                res = "assets/notes/C5.mp3";
                break;
            case "Db5":
                res = "assets/notes/Db5.mp3";
                break;
            case "D5":
                res = "assets/notes/D5.mp3";
                break;
            case "D#5":
                res = "assets/notes/Eb5.mp3";
                break;
            case "Dx5":
                res = "assets/notes/E5.mp3";
                break;
            case "Ebb5":
                res = "assets/notes/D5.mp3";
                break;
            case "Eb5":
                res = "assets/notes/Eb5.mp3";
                break;
            case "E5":
                res = "assets/notes/E5.mp3";
                break;
            case "E#5":
                res = "assets/notes/F5.mp3";
                break;
            case "Ex5":
                res = "assets/notes/Gb5.mp3";
                break;  
            case "Exx5":
                res = "assets/notes/G5.mp3";
                break;
            case "Fbb5":
                res = "assets/notes/Eb5.mp3";
                break;
            case "Fb5":
                res = "assets/notes/E5.mp3";
                break;
            case "F5":
                res = "assets/notes/F5.mp3";
                break;
            case "F#5":
                res = "assets/notes/Gb5.mp3";
                break;
            case "Fx5":
                res = "assets/notes/G5.mp3";
                break;
            case "Gbb5":
                res = "assets/notes/F5.mp3";
                break;
            case "Gb5":
                res = "assets/notes/Gb5.mp3";
                break;
            case "G5":
                res = "assets/notes/G5.mp3";
                break;
            case "G#5":
                res = "assets/notes/Ab5.mp3";
                break;
            case "Gx5":
                res = "assets/notes/A5.mp3";
                break;
            case "Abb5":
                res = "assets/notes/G5.mp3";
                break;
            case "Ab5":
                res = "assets/notes/Ab5.mp3";
                break;
            case "A5":
                res = "assets/notes/A5.mp3";
                break;
            case "A#5":
                res = "assets/notes/Bb5.mp3";
                break;
            case "Ax5":
                res = "assets/notes/B5.mp3";
                break;
            case "Axx5": 
                res = "assets/notes/C6.mp3";
                break;
            case "Bbb5":
                res = "assets/notes/A5.mp3";
                break;
            case "Bb5":
                res = "assets/notes/Bb5.mp3";
                break;
            case "B5":
                res = "assets/notes/B5.mp3";
                break;
            case "B#5":
                res = "assets/notes/C6.mp3";
                break;
            case "Bx5":
                res = "assets/notes/Db6.mp3";
                break;   
            case "Cbb6":
                res = "assets/notes/Bb.mp3";
                break;
            case "Cb6":
                res = "assets/notes/B5.mp3";
                break;
            case "C6":
                res = "assets/notes/C6.mp3";
                break;
            case "C#6":
                res = "assets/notes/Db5.mp3";
                break;
        }
        return res;
    }

    //Maps the fully qualified note name to its frequency
    private determineFrequency(): number{
        let res: number = 0;
        switch(this.fullName){
            case "A1":
                res = 55.00000;
                break;
            case "A#1":
                res = 58.27047;
                break;
            case "Ax1":
                res = 61.73541;
                break;
            case "Bbb1":
                res = 55.00000;
                break;
            case "Bb1":
                res = 58.27047;
                break;
            case "B1":
                res = 61.73541;
                break;
            case "B#1":
                res = 65.40639;
                break;
            case "Bx1":
                res = 69.29566;
                break;
            case "Cbb2":
                res = 58.27047;
                break;
            case "Cb2":
                res = 61.73541;
                break;
            case "C2":
                res = 65.40639;
                break;
            case "C#2":
                res = 69.29566;
                break;
            case "Cx2":
                res = 73.41619;
                break;
            case "Dbb2":
                res = 65.40639;
                break;
            case "Db2":
                res = 69.29566;
                break;
            case "D2":
                res = 73.41619;
                break;
            case "D#2":
                res = 77.78175;
                break;
            case "Dx2":
                res = 82.40689;
                break;
            case "Ebb2":
                res = 73.41619;
                break;
            case "Eb2":
                res = 77.78175;
                break;
            case "E2":
                res = 82.40689;
                break;
            case "E#2":
                res = 87.30706;
                break;
            case "Ex2":
                res = 92.49861;
                break;
            case "Fbb2":
                res = 77.78175;
                break;
            case "Fb2":
                res = 82.40689;
                break;
            case "F2":
                res = 87.30706;
                break;
            case "F#2":
                res = 92.49861;
                break;
            case "Fx2":
                res = 97.99886;
                break;
            case "Gbb2":
                res = 87.30706;
                break;
            case "Gb2":
                res = 92.49861;
                break;
            case "G2":
                res = 97.99886;
                break;
            case "G#2":
                res = 103.8262;
                break;
            case "Gx2":
                res = 110.0000;
                break;
            case "Abb2":
                res = 97.99886;
                break;
            case "Ab2":
                res = 103.8262;
                break;
            case "A2":
                res = 110.0000;
                break;
            case "A#2":
                res = 116.5409;
                break;
            case "Ax2":
                res = 123.4708;
                break;
            case "Bbb2":
                res = 110.0000;
                break;
            case "Bb2":
                res = 116.5409;
                break;
            case "B2":
                res = 123.4708;
                break;
            case "B#2":
                res = 130.8128;
                break;
            case "Bx2":
                res = 138.5913;
                break;
            case "Cbb3":
                res = 116.5409;
                break;
            case "Cb3":
                res = 123.4708;
                break;
            case "C3":
                res = 130.8128;
                break;
            case "C#3":
                res = 138.5913;
                break;
            case "Cx3":
                res = 146.8324;
                break;
            case "Dbb3":
                res = 130.8128;
                break;
            case "Db3":
                res = 138.5913;
                break;
            case "D3":
                res = 146.8324;
                break;
            case "D#3":
                res = 155.5635;
                break;
            case "Dx3":
                res = 164.8138;
                break;
            case "Ebb3":
                res = 146.8324;
                break;
            case "Eb3":
                res = 155.5635;
                break;
            case "E3":
                res = 164.8138;
                break;
            case "E#3":
                res = 174.6141;
                break;
            case "Ex3":
                res = 184.9972;
                break;
            case "Fbb3":
                res = 155.5635;
                break;
            case "Fb3":
                res = 164.8138;
                break;
            case "F3":
                res = 174.6141
                break;
            case "F#3":
                res = 184.9972	
                break;
            case "Fx3":
                res = 195.9977	
                break;
            case "Gbb3":
                res = 174.6141
                break;
            case "Gb3":
                res = 184.9972	
                break;
            case "G3":
                res = 195.9977	
                break;
            case "G#3":
                res = 207.6523		
                break;
            case "Gx3":
                res = 220.0000;
                break;
            case "Abb3":
                res = 195.9977		
                break;
            case "Ab3":
                res = 207.6523		
                break;
            case "A3":
                res = 220.0000;
                break;
            case "A#3":
                res = 233.0819;
                break;
            case "Ax3":
                res = 246.9417;
                break;
            case "Bbb3":
                res = 220.0000;
                break;
            case "Bb3":
                res = 233.0819;
                break;
            case "B3":
                res = 246.9417;
                break;
            case "B#3":
                res = 261.6256;
                break;
            case "Bx3":
                res = 277.1826;
                break;
            case "Cbb4":
                res = 233.0819;
                break;
            case "Cb4":
                res = 246.9417;
                break;
            case "C4":
                res = 261.6256;
                break;
            case "C#4":
                res = 277.1826;
                break;
            case "Cx4":
                res = 293.6648;
                break;
            case "Dbb4":
                res = 261.6256;
                break;
            case "Db4":
                res = 277.1826;
                break;
            case "D4":
                res = 293.6648;
                break;
            case "D#4":
                res = 311.1270;
                break;
            case "Dx4":
                res = 329.6276;
                break;
            case "Ebb4":
                res = 293.6648;
                break;
            case "Eb4":
                res = 311.1270;
                break;
            case "E4":
                res = 329.6276;
                break;
            case "E#4":
                res = 349.2282;
                break;
            case "Ex4":
                res = 369.9944;
                break;
            case "Fbb4":
                res = 311.1270;
                break;
            case "Fb4":
                res = 329.6276;
                break;
            case "F4":
                res = 349.2282;
                break;
            case "F#4":
                res = 369.9944;
                break;
            case "Fx4":
                res = 391.9954;
                break;
            case "Gbb4":
                res = 349.2282;
                break;
            case "Gb4":
                res = 369.9944;
                break;
            case "G4":
                res = 391.9954;
                break;
            case "G#4":
                res = 415.3047;
                break;
            case "Gx4":
                res = 440.0000;
                break;
            case "Abb4":
                res = 391.9954;
                break;
            case "Ab4":
                res = 415.3047;
                break;
            case "A4":
                res = 440.0000;
                break;
            case "A#4":
                res = 466.1638;
                break;
            case "Ax4":
                res = 493.8833;
                break;
            case "Bbb4":
                res = 440.0000;
                break;
            case "Bb4":
                res = 466.1638;
                break;
            case "B4":
                res = 493.8833;
                break;
            case "B#4":
                res = 523.2511;
                break;
            case "Bx4":
                res = 554.3653;
                break;
            case "Cbb5":
                res = 466.1638;
                break;
            case "Cb5":
                res = 493.8833;
                break;
            case "C5":
                res = 523.2511;
                break;
            case "C#5":
                res = 554.3653;
                break;
            case "Cx5":
                res = 587.3295;
                break;
            case "Dbb5":
                res = 523.2511;
                break;
            case "Db5":
                res = 554.3653;
                break;
            case "D5":
                res = 587.3295;
                break;
            case "D#5":
                res = 622.2540;
                break;
            case "Dx5":
                res = 659.2551;
                break;
            case "Ebb5":
                res = 587.3295;
                break;
            case "Eb5":
                res = 622.2540;
                break;
            case "E5":
                res = 659.2551;
                break;
            case "E#5":
                res = 698.4565;
                break;
            case "Ex5":
                res = 739.9888;
                break;
            case "Fbb5":
                res = 622.2540;
                break;
            case "Fb5":
                res = 659.2551;
                break;
            case "F5":
                res = 698.4565;
                break;
            case "F#5":
                res = 739.9888;
                break;
            case "Fx5":
                res = 783.9909;
                break;
            case "Gbb5":
                res = 698.4565;
                break;
            case "Gb5":
                res = 739.9888;
                break;
            case "G5":
                res = 783.9909;
                break;
            case "G#5":
                res = 830.6094;
                break;
            case "Gx5":
                res = 880.0000;
                break;
            case "Abb5":
                res = 783.9909;
                break;
            case "Ab5":
                res = 830.6094;
                break;
            case "A5":
                res = 880.0000;
                break;
            case "A#5":
                res = 932.3275;
                break;
            case "Ax5":
                res = 987.7666;
                break;
            case "Bbb5":
                res = 880.0000;
                break;
            case "Bb5":
                res = 932.3275;
                break;
            case "B5":
                res = 987.7666;
                break;
            case "B#5":
                res = 1046.502;
                break;	
            case "Bx5":
                res = 1108.731;
                break;
            case "Cbb6":
                res = 932.3275;
                break;
            case "Cb6":
                res = 987.7666;
                break;
            case "C6":
                res = 1046.502;
                break;	
            case "C#6":
                res = 1108.731;
                break;
            case "Cx6":
                res = 1174.659;
                break;
            case "Dbb6":
                res = 1046.502;
                break;
            case "Db6":
                res = 1108.731;
                break;
            case "D6":
                res = 1174.659;
                break;
            case "D#6":
                res = 1244.508;
                break;
            case "Dx6":
                res = 1318.510;
                break;
            case "Ebb6":
                res = 1174.659;
                break;
            case "Eb6":
                res = 1244.508;
                break;
            case "E6":
                res = 1318.510;
                break;
            case "E#6":
                res = 1396.913;
                break;
        }
        return res;
    }

    //Splits the note and octave from its fullname
    private splitNote(fullName: string): {root: string, octave: number}{
        const regex: RegExp = /^([A-Ga-g](?:#|b|x){0,2})(\d+)$/;
        const match: RegExpMatchArray = fullName.match(regex) as RegExpMatchArray;

        if(match){
            const root: string = match[1];
            const octave: number = parseInt(match[2], 10);
            return {root, octave};
        } else{
            return null!;
        }
    }

}