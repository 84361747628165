import { Component, OnInit, ChangeDetectionStrategy, Inject, ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScoreDetail, ScoreRow } from './ScoreTypes';
import { GameId } from '../../constants/GameIdEnum';
import { ChordQuality } from '../../constants/ChordEnum';
import { CommonUtil } from '../../util/CommonUtil';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogModule } from '@angular/material/dialog';
import { IntervalQuality } from '../../constants/IntervalQualityConstants';
import { ScaleQuality } from '../../constants/ScaleQualityConstants';
import { AuthenticationService } from '../../auth/service/AuthenticationService';
import { GameDataService } from '../../data-services/GameDataService';



@Component({
  selector: 'app-score-sheet',
  standalone: true,
  imports: [CommonModule, MatDialogModule],
  templateUrl: './score-sheet.component.html',
  styleUrl: './score-sheet.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScoreSheetComponent implements OnInit{
    private gameId: number = 0;
    public score: number = 0;
    public totalCorrect: number = 0;
    public totalQuestions: number = 1;
    public displayCount: number = 0;
    public difficulty: number = 0;
    public mode: boolean = false;
    public time: string = "0:00";
    public accuracy: string = "";
    public scoreRows: ScoreRow[] = [];
    public scoreDetails: ScoreDetail[] = [];

    public scoreMessage_notLoggedIn: string = "You must be logged in to save your score on the leaderboard.";
    public scoreErrorMessage: string = "There was an error with saving your score.  We apologize for the inconvenience.";
    public scoreSuccessMessage: string = "Your score has been saved.";
    public isVisible: boolean = true;
    public isMessageVisible: boolean = false;
    public isSaved: boolean = false;

    constructor(@Inject(MAT_DIALOG_DATA) public payload: any, private authService: AuthenticationService, private cdr: ChangeDetectorRef){}

    async ngOnInit(): Promise<void> {
        if (this.payload) {
            this.scoreRows = this.payload['scoreRows'];
            this.score = this.payload['score'];
            this.gameId = this.payload['gameId'];
            this.difficulty = this.payload['difficulty'];
            this.time = CommonUtil.getTimer(this.payload['time']);
            this.mode = this.payload['mode'];

            //Set total correct value
            this.totalQuestions = this.scoreRows.length;
            this.totalCorrect = this.getCorrectAnswers(this.scoreRows);
            
            //Set accuracy to 0 if no questions were attempted
            if(this.totalQuestions === 0){
                this.accuracy = "0.00"
            } else {
                this.accuracy = ((this.totalCorrect / this.totalQuestions) * 100).toFixed(2);
            }

            //Get a list of unique ids from the score rows array
            //Ids will be different depending on gameID
            let ids: any[] = Array.from(new Set(this.scoreRows.map(scoreRow => scoreRow.expectedId)));

            //If score rows were retrieved, populate score details array
            if(this.scoreRows != null && ids != null){
                this.populateScoreDetails(ids);
            }

            //If difficulty is challenge mode and the user is logged in, save score to the db
            if(this.mode && this.isLoggedIn()){
                this.isSaved = await this.saveScoreRecord();
                this.cdr.detectChanges();
            }

            this.isMessageVisible = true;
            this.cdr.detectChanges();

        } 
    }

    //Handles alternating color scheme for score details table
    public shouldDisplay(): boolean {
        this.displayCount++;
        return true;
    }

    //Colors background for header and buttons depending on game ID
    public changeBackgroundColor(): string{
        if(this.gameId == GameId.EarTraining_Chords || this.gameId == GameId.EarTraining_Intervals ||
           this.gameId == GameId.EarTraining_Scales
        ){
            return "ear-training-container";
        } else if (this.gameId == GameId.StaffIdentification_Chords || this.gameId == GameId.StaffIdentification_Intervals ||
                   this.gameId == GameId.StaffIdentification_Notes || this.gameId == GameId.StaffIdentification_Scales
        ){
            return "staff-identification-container";
        } else if(this.gameId == GameId.StaffBuilding_Chords || this.gameId == GameId.StaffBuilding_Intervals ||
                  this.gameId == GameId.StaffBuilding_Scales
        ){
            return "staff-building-container";
        } else{
            return "default-container";
        }
    }
    
    //Returns class for altering background color on every other score row entry
    public getRowClass(): string {
        const isEven: boolean = this.displayCount % 2 === 0;
        const backgroundColor: string = this.changeBackgroundColor();
        if(isEven){
            if(backgroundColor === "ear-training-container"){
                return "ear-training-container-alt";
            } else if(backgroundColor === "staff-identification-container"){
                return "staff-identification-container-alt";
            } else if(backgroundColor === "staff-building-container"){
                return "staff-building-container-alt";
            } else {
                return "default-container-alt";
            }
        } else{
            return backgroundColor;
        }
    }

    //Adds bottom border to scoreboard entry
    public addBottomBorder(i: number){
        return i < this.scoreDetails.length ? 'bottom-border' : '';
    }


    //Gets total number of correct answers
    private getCorrectAnswers(scoreRows: ScoreRow[]): number{
        let res: number = 0;
        for(let i = 0; i < scoreRows.length; i++){
            if(scoreRows[i].id == scoreRows[i].expectedId){
                res += 1;
            }
        }
        return res;
    }

    //Checks if a user is logged in
    public isLoggedIn(): boolean{
        return this.authService.isLoggedIn();
    }

    //Adds the score details to the score table
    public addEntryToScores(): boolean{
        return false;
    }

    //Populate score details array based on series of answers from game module
    private populateScoreDetails(ids: any[]): void{
        let totalQuestions: number = 0;
        let totalCorrect: number = 0;
        let rowQuestions: number = 0;
        let rowCorrect: number = 0;
        let accuracy: string = "";
        let name: string = "";
        for(let i = 0; i < ids.length; i++){
            for(let j = 0; j < this.scoreRows.length; j++){
                if(this.scoreRows[j].expectedId == ids[i]){
                    if(this.scoreRows[j].id == this.scoreRows[j].expectedId){
                        totalCorrect += 1;
                        rowCorrect += 1;
                    }
                    totalQuestions += 1;    
                    rowQuestions += 1;
                }
            }

            accuracy = ((totalCorrect / totalQuestions) * 100).toFixed(2);
            name = this.determineName(ids[i]);

            let scoreDetail: ScoreDetail = {
                id: 1,
                name: name,
                totalCorrect: totalCorrect,
                totalQuestions: totalQuestions,
                accuracy: accuracy
            }
            totalCorrect = 0;
            totalQuestions = 0;
            this.scoreDetails.push(scoreDetail);
            
        }
    }
    
    //Returns the name of the score details based on game id and score row id
    private determineName(id: any): string{
        if(this.gameId === GameId.EarTraining_Chords ||
            this.gameId === GameId.StaffIdentification_Chords ||
            this.gameId === GameId.StaffBuilding_Chords
        ){
            return this.getChordName(id);
        } else if(this.gameId === GameId.EarTraining_Intervals ||
                  this.gameId === GameId.StaffIdentification_Intervals ||
                  this.gameId === GameId.StaffBuilding_Intervals
        ){
            return this.getIntervalName(id);
        } else if(this.gameId === GameId.EarTraining_Scales ||
                  this.gameId === GameId.StaffIdentification_Scales ||
                  this.gameId === GameId.StaffBuilding_Scales
        ){
            return this.getScaleName(id);
        } else if(this.gameId === GameId.StaffIdentification_Notes){
            return id;
        } 

        return "";
    }

    //Returns proper chord name based on score row id
    private getChordName(id: number): string{
        switch(id){
            case ChordQuality.Major:
                return "Major Triads";
            case ChordQuality.Minor:
                return "Minor Triads";
            case ChordQuality.Aug:
                return "Augmented Triads";
            case ChordQuality.Dim:
                return "Diminished Triads";
            case ChordQuality.Maj7:
                return "Maj7";
            case ChordQuality.Dom7:
                return "Dom7";
            case ChordQuality.Min7:
                return "Min7";
            case ChordQuality.HalfDim7:
                return "Min7b5 (HalfDim7)";
            case ChordQuality.Dim7:
                return "Dim7";
            case ChordQuality.Maj9:
                return "Maj9";
            case ChordQuality.Dom9:
                return "Dom9";
            case ChordQuality.Min9:
                return "Min9";
            case ChordQuality.DomFlat9:
                return "Dom97b9";
            case ChordQuality.DomSharp9:
                return "Dom7#9";
            case ChordQuality.Maj11:
                return "Maj11";
            case ChordQuality.Dom11:
                return "Dom11";
            case ChordQuality.Min11:
                return "Min11";
            case ChordQuality.DomSharp11:
                return "Dom7#11";
            case ChordQuality.Maj13:
                return "Maj13";
            case ChordQuality.Dom13:
                return "Dom13";
            case ChordQuality.Min13:
                return "Min13";
            default:
                return "";
        }
    }

    private getIntervalName(id: number): string{
        switch(id){
            case IntervalQuality.MINOR_SECOND:
                return "Minor 2nd (m2)";
            case IntervalQuality.MAJOR_SECOND:
                return "Major 2nd (M2)";
            case IntervalQuality.MINOR_THIRD:
                return "Minor 3rd (m3)";
            case IntervalQuality.MAJOR_THIRD:
                return "Major 3rd (M3)";
            case IntervalQuality.PERFECT_FOURTH:
                return "Perfect 4th (P4)";
            case IntervalQuality.TRITONE:
                return "Tritone (A4)";
            case IntervalQuality.PERFECT_FIFTH:
                return "Perfect 5th (P5)";
            case IntervalQuality.MINOR_SIXTH:
                return "Minor 6th (m6)";
            case IntervalQuality.MAJOR_SIXTH:
                return "Major 6th (M6)";
            case IntervalQuality.MINOR_SEVENTH:
                return "Minor 7th (m7)";
            case IntervalQuality.MAJOR_SEVENTH:
                return "Major 7th (M7)";
            case IntervalQuality.OCTAVE:
                return "Octave (P8)";
            default:
                return "";
        }
    }

    private getScaleName(id: number): string{
        switch(id){
            case ScaleQuality.MAJOR:
                return "Major";
            case ScaleQuality.NATURAL_MINOR:
                return "Natural Minor";
            case ScaleQuality.MELODIC_MINOR:
                return "Melodic Minor";
            case ScaleQuality.HARMONIC_MINOR:
                return "Harmonic Minor";
            case ScaleQuality.HARMONIC_MAJOR:
                return "Harmonic Major";
            case ScaleQuality.IONIAN:
                return "Ionian";
            case ScaleQuality.DORIAN:
                return "Dorian";
            case ScaleQuality.PHRYGIAN:
                return "Phrygian";
            case ScaleQuality.LYDIAN:
                return "Lydian";
            case ScaleQuality.MIXOLYDIAN:
                return "Mixolydian";
            case ScaleQuality.AEOLIAN:
                return "Aeolian";
            case ScaleQuality.LOCRIAN:
                return "Locrian";
            case ScaleQuality.PERSIAN:
                return "Persian";
            case ScaleQuality.HUNGARIAN_MINOR:
                return "Hungarian Minor";
            case ScaleQuality.DOUBLE_HARMONIC:
                return "Double Harmonic";
            case ScaleQuality.ENIGMATIC:
                return "Enigmatic";
            case ScaleQuality.NEAPOLITAN_MAJOR:
                return "Neapolitan Major";
            case ScaleQuality.NEAPOLITAN_MINOR:
                return "Neapolitan Minor";
            case ScaleQuality.HALF_WHOLE_DIMINISHED:
                return "Half-Whole Diminished";
            default:
                return "";
        }
    }

    //Makes API call for saving the user's score in the record
    private async saveScoreRecord(): Promise<boolean>{
        return new Promise(async (resolve) => {
            const userIdString: string = this.authService.getId() as string;
            let userId: number = -1;
            if(userIdString != null){
                userId = Number.parseInt(userIdString);
            }
    
            //User is valid, proceed to save the score
            if(userId > 0){
                const res: string = await GameDataService.setLeaderboardRecord(this.gameId, userId, this.totalCorrect, this.totalQuestions, 
                                                    this.accuracy, new Date(), this.difficulty, this.score);
                const jsonObject = JSON.parse(res);
                const isScoreSet: boolean = jsonObject.scoreSet;
    
                //Update message to alert user that the score was not saved
                if(isScoreSet){
                    resolve(true);
                }
    
                resolve(false);
    
            }
        })
        
    }

}
