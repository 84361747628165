import { NavBarComponent } from './nav-bar/nav-bar.component';
import { SideMenuComponent } from "./side-menu/side-menu.component";
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { Component, Inject, PLATFORM_ID  } from '@angular/core';
import { FooterComponent } from "./footer/footer.component";
import { CommonModule, isPlatformBrowser } from '@angular/common';


@Component({
    selector: 'app-root',
    standalone: true,
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    imports: [MatToolbarModule, NavBarComponent, MatIconModule,
    MatSidenavModule, SideMenuComponent, FooterComponent, CommonModule]
})
export class AppComponent{
    //Used to track is display is compatible with desktop layout
    incompatible: boolean = false;

    constructor(@Inject(PLATFORM_ID) private platformId: object){
        if(isPlatformBrowser(this.platformId)){
            //Register service worker for caching audio files
            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.register('/assets/sw.js').then(registration => {
                    console.log('Service Worker successfully registered');
                }).catch(error => {
                    console.error('Service Worker registration failed:', error);
                });
            }
        }
    }
}
