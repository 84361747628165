<div class="app-parent-container">
    <div class="main-container margin-top">
        <div class="logo-container margin-top-icon">
            <img src="/assets/icons/BeatTheory-banner.png" />
            <div>
                <p >Enter your email address to reset your password:</p>
    
                <input type="text" [(ngModel)]="email"/>
                <div class="auth-control-container">
                    <button type="submit" (click)="sendEmail()">Send email</button>
                </div>
                <p *ngIf="emailSentError_emailNotSent" class="auth-error-message auth-error-message-left">
                    There was an error with sending the email.  Please try again later.
                </p>
                <p *ngIf="emailSentError_invalidEmail" class="auth-error-message auth-error-message-left">
                    The email address is either invalid or doesn't exist.
                </p>
                <p *ngIf="emailSentError_serviceUnavailable" class="auth-error-message auth-error-message-left">
                    The service is currently unavailable.  Please try again later.
                </p>
        
                <p *ngIf="emailSentSuccessfully">
                    <b>Password reset email has been sent.  Please check your inbox.</b>
                </p>
    
            </div>
    
            <div *ngIf="sendingEmail">
                <app-spinner *ngIf="sendingEmail"></app-spinner>
            </div>
            
        </div>    
    </div>
</div>

