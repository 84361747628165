
//Util class for basic operations related to staff elements
export class StaffUtil{
    //Checks if a note is sharp
    public static isSharp(note: string): boolean{
        const sharpRegex: RegExp = /^(?!.*[A-G]b)(?=.*#)[A-G]#?$/;
        return sharpRegex.test(note);
    }

    //Checks if a note is double sharp
    public static isDoubleSharp(note: string): boolean{
        const doubleSharpRegex: RegExp = /.x$/;
        return doubleSharpRegex.test(note);
    }

    //Checks if a note is double flat
    public static isDoubleFlat(note: string): boolean{
        const doubleFlatRegex: RegExp = /.bb$/;
        return doubleFlatRegex.test(note);
    }

    //Checks if a note is flat
    public static isFlat(note: string): boolean{
        const flatRegex: RegExp = /^(.*b){1}$/;
        return flatRegex.test(note) && note.length <= 2;
    }

    //Checks if a note is triple sharp
    public static isTripleSharp(note: string): boolean{
        const tripleSharpRegex: RegExp = /xx/;
        return tripleSharpRegex.test(note);
    }

    public static isPitchModified(root: string): boolean{
        return StaffUtil.isDoubleFlat(root) || 
        StaffUtil.isFlat(root) || 
        StaffUtil.isSharp(root) || 
        StaffUtil.isDoubleSharp(root);
    }

    //Splits an enharmonic note from the notes list and returns one of them
    public static splitNote(note: string, key?: string){
        let res: string = "";
        try{
            const notes: string[] = note.split('/');
            if(notes.length < 2){
                res = note;
            } else {
                const randomIndex: number = Math.floor(Math.random() * notes.length);
                res = notes[randomIndex].trim();

            }
        } catch(error){
            console.error("Error with splitting note " + note);
        }
        
        return res;

    }

    //Constructs an SVG note string to be used with the StaffBuilder canvas
    public static constructSVGNote(canvas: HTMLCanvasElement, pixelRatio: number, fill: string,
        isRotated: boolean
    ): string{
        let isMobile: boolean = pixelRatio > 1;

        let noteWidth: number = 0;
        let noteHeight: number = 0;
        if(isMobile){
            noteWidth = 80 * ((3/2) * pixelRatio);
            noteHeight = 160 * ((3/2) *pixelRatio);
        } else {
            noteWidth = 80;
            noteHeight = 160;
        }
        
        
        let tailX: number = 0;
        let tailY: number = 0;
        const ellipseX: number = isMobile ? 35 : 50;
        let ellipseY: number = 0;
        // const tailX: number = isRotated ? 25 : 63;
        // const tailY: number = isRotated ? 10 : -14;
        const tailWidth: number = 12;
        const tailHeight: number = 100;

// \        const ellipseY = isRotated ? 6 : 85;
        if(isRotated){
            tailX = isMobile ? 10.75 : 25.5;
            tailY = isMobile ? 3 : 10;
            ellipseY = isMobile ? 6 : 6;
        } else {
            tailX = isMobile ? 47.5 : 62.5;
            tailY = isMobile ? -16 : -14;
            ellipseY = isMobile ?  83 : 85;
        }

        const ellipseRadiusX: number = 18;
        const ellipseRadiusY: number = 25;

        return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="${noteWidth}" height="${noteHeight}">
        '<rect x="${tailX}" y="${tailY}" width="${tailWidth}" height="${tailHeight}" fill="${fill}"/>
        '<ellipse cx="${ellipseX}" cy="${ellipseY}" rx="${ellipseRadiusX}" ry="${ellipseRadiusY}" fill="${fill}" transform="rotate(75 ${ellipseX} ${ellipseY})"/>
        '</svg>`;
    }

}