<div class="app-parent-container">
    <div class="main-container">
        <div class="logo-container logo-container-white margin-top-icon">
            <img src="/assets/icons/BeatTheory-banner.png" /> 
        </div>
    
        <div class="changelog-container">
            <!-- PATCH 0.9.2.1 (12/02/2024) -->
            <div id="entry_20240712" class="changelog-entry">
                <div class="changelog-header">
                    <h2>v.0.9.3 - 2024-07-12</h2>
                    <h3 id="entry_20240712_sign" (click)="expandNotes($event)">-</h3>
                </div>
                <div class="changelog-body">
                    <ul>
                        <li>Redesigned audio playback architecture for Ear Training modules</li>
                        <li><b>DEV NOTE - The audio playback functionality has frequent jitter issues, despite introducing caching and downgrading from .wav to
                            .mp3 files.  This update should minimize latency after the audio files have been cached
                        </b></li>
                    </ul>
                </div>
            </div>
            <!-- PATCH 0.9.2.1 (12/02/2024) -->
            <div id="entry_20240212" class="changelog-entry">
                <div class="changelog-header">
                    <h2>v.0.9.2.1 - 2024-02-12</h2>
                    <h3 id="entry_20240212_sign" (click)="expandNotes($event)">+</h3>
                </div>
                <div class="changelog-body hidden">
                    <ul>
                        <li>Implemented universal use of Noto Music font</li>
                        <li>Staff building modules now preload fonts before drawing them to the canvas</li>
                        <li>Modified out-of-bounds checks in staff building games to be more precise</li>
                        <li>Fixed background color overflow for several buttons</li>
                    </ul>
                </div>
            </div>
            <!-- PATCH 0.9.2 (11/25/2024) -->
            <div id="entry_20242511" class="changelog-entry">
                <div class="changelog-header">
                    <h2>v.0.9.2 - 2024-25-11</h2>
                    <h3 id="entry_20242511_sign" (click)="expandNotes($event)">+</h3>
                </div>
                <div class="changelog-body hidden">
                    <ul>
                        <li>Mobile compatibility - All learning modules are now compatible with mobile and tablet devices</li>
                        <ul>
                            <li>Staff Identification and Staff Building screens resize proportionately to device resolution</li>
                            <li>Staff Building games now feature a touch-and-drag event listener for placing notes</li>
                            <li>Staff Building games now feature buttons for adding pitch modifiers and removing/clearing notes from the screen</li>        
                        </ul>
                        <li>Staff Identification Modules</li>
                        <ul>
                            <li>Redesigned graphics rendering and screen sizes</li>
                        </ul>
                        <li>Staff Building Modules</li>
                        <ul>
                            <li>Redesigned keyboard control instructions</li>
                            <li>Added a new keyboard control for removing all the notes from the screen</li>
                        </ul>
                        <li>General UI Changes</li>
                        <ul>
                            <li>Reformatted dynamic margins for About Us and Changelog pages for better readability</li>
                            <li>Extended the height of the body content on all pages</li>
                            <li>Added box shadows to the body content on all pages</li>
                        </ul>
                    </ul>
                </div>
            </div>
            <!-- PATCH 0.9.1 -->
            <div id="entry_20242710" class="changelog-entry">
                <div class="changelog-header">
                    <h2>v.0.9.1 - 2024-27-10</h2>
                    <h3 id="entry_20242710_sign" (click)="expandNotes($event)">+</h3>
                </div>
                <div class="changelog-body hidden">
                    <ul>
                        <li>Implemented mobile compatibility</li>
                        <ul>
                            <li>Redesigned UI for 5/10 modules to be mobile compatible</li>
                            <li>Redesigned UI for leaderboard and profile record tables to be mobile compatible</li>
                            <li>Redesigned UI for difficulty selection screens to be mobile compatible</li>
                            <li>Redesigned UI for the score sheet to be mobile compatible</li>
                            <li>Redesigned UI for the navbar to remove links on mobile devices</li>
                            <li><b>DEV NOTE - I am still working on mobile compatibility for the Staff Identification and 
                            Staff Building games.  Due to limitations of the graphical architecture used for these categories, I need more time to introduce a
                            proper fix.  These games are still available for devices with a resolution width greater than 1024px</b></li>
                        </ul>
                        <li>General UI changes</li>
                        <ul>
                            <li>Redesigned text and button layout of the difficulty select screens</li>
                            <li>Removed Total Correct and Total Attempted columns from the leaderboard and profile records</li>
                            <li>Redesigned button layout for the game modules to be less clunky</li>
                            <li>Updated naming convention for interval and chord game buttons to use shorthand notation</li>
                            <li>Adjusted error message size and layout</li>
                            <li>Added dynamic background colors that change depending on what game module you are playing</li>
                            <li>Added footer with version number</li>
                        </ul>
                        <li>Implemented caching of audio and image files for more response experience</li>
                        <li>Fixed Ear Training - Scale Identification game bug where Ionian and Aeolian modes were not being
                            correctly identified
                        </li>
                    </ul>
                </div>
            </div>
    
        
        </div>
    
    </div>
    
</div>
