import { ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { StaffGameHandlerComponent } from '../../staff-game-handler/staff-game-handler.component';
import { GameId } from '../../../constants/GameIdEnum';
import { Staff } from '../../../staff-graphics/models/Staff';
import { StaffBuilder } from '../../../staff-graphics/StaffBuilder';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SharedDataService } from '../../../util/SharedDataService';
import { StaffChord } from '../../../staff-graphics/models/StaffChord';
import { Chord } from '../../../models/Chord';

@Component({
  selector: 'app-staff-building-chord-game',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './../../staff-game-handler/staff-game-handler.component.html',
  styleUrls: ['./../../staff-game-handler/staff-game-handler.component.css', "./staff-building-chord-game.component.css"]
})
export class StaffBuildingChordGameComponent extends StaffGameHandlerComponent implements OnInit, OnDestroy{
    public override gameId = GameId.StaffBuilding_Chords;
    override initialCountdown: number = 600;
    private staff: Staff = new Staff([4, 4], []);
    protected override clickEnabled: boolean = true;
    
    constructor(protected override router: Router, protected override sharedDataService: SharedDataService, protected override dialog: MatDialog, 
                protected override route: ActivatedRoute, @Inject(PLATFORM_ID) private platformId: Object, private cdr: ChangeDetectorRef){
        super(router, sharedDataService, dialog, route);
    }

    override ngOnInit(): void {
        this.initModule();
    }

    async ngAfterViewInit(){
        if(isPlatformBrowser(this.platformId)){
            this.setCanvasSize();   

            const chord: Chord = this.generateRandomChord(this.isTrebleClef);
            this.randomElement = new StaffChord(chord, this.canvas.nativeElement);
            
            this.builder = new StaffBuilder(this.staff, this.canvas.nativeElement, this.gameId);
            await this.builder.loadFont();
            this.builder.drawSimpleStaff(this.randomElement, this.isTrebleClef);
            //Reload after NotoMusic font loads
            setTimeout(async () =>{
                this.canvas.nativeElement.getContext('2d')?.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
                await this.builder.drawSimpleStaff(this.randomElement, this.isTrebleClef);
            }, 700);

            window.addEventListener('resize', () =>{
                this.setCanvasSize();
                this.builder.drawSimpleStaff(this.randomElement, this.isTrebleClef);

                const rect = this.canvas.nativeElement.getBoundingClientRect();
                const displayWidth = rect.width;
                const displayHeight = rect.height;
                this.builder.canvasStyleWidth = displayWidth;
                this.builder.canvasStyleHeight = displayHeight;
                this.builder.setExtendedLinesPosition();

            });
            

            this.enableEventHandlers();
            this.cdr.detectChanges();
        }

    }
}
