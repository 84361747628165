
<!-- Display in case connection is unavailable -->
<app-unavailable *ngIf="serviceUnavailable && isLoaded">
</app-unavailable>

<div class="app-parent-container">
    <div *ngIf="isLoaded && isValidUser" class="profile-container">
        <div class="settings-parent-container">
            <div class="settings-container" (mouseenter)="onHoverGearIcon()" (mouseleave)="offHoverGearIcon()">
                <img src="assets/icons/gear.png" 
                [ngClass]="{'color-change-image': isGearHovered}"/>
                <div class="modal-content" *ngIf="isGearHovered">
                    <div class="modal-selection">
                        <p (click)="openUsernameChangeDialogBox()">Change username</p>
                    </div>
                    <div class="modal-selection">
                        <p (click)="openPasswordChangeDialogBox()">Change password</p>
                    </div>
                    <div class="modal-selection">
                        <p (click)="openProfilePictureDialogBox()">Change profile picture</p>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="header-container">
            <div></div>
            <div class="image-container" [class.darken]="showOverlay">
                
                <img class="profile-image" [src]="profilePicUrl" ngSrcset="100w, 200w"/>
           </div>
            <div class="header-details">
                <div>
                    <h2 *ngIf="isValidUser" >{{username}}</h2>
                    <p *ngIf="isValidUser">Date joined: {{pipeDate(dateJoined)}}</p>
                    <p *ngIf="!isValidUser">No user found</p>
                </div>
            </div>
            <div></div>
        </div>
        
        <div>
    
            <div class="body-container">
                <h3>My Top Scores:</h3>
                <p *ngIf="records.length === 0">You do not have any top scores yet.  Your top scores will appear here after playing some games in challenge mode.</p>
            </div> 
    
            <table *ngIf="records.length > 0" class="leaderboard-table">
                <thead>
                    <tr>
                        <th>Game Type</th>
                        <th>Difficulty</th>
                        <th>Score</th>
                        <th>Accuracy</th>
                        <th>Date</th>
                    </tr>
                </thead>
        
                <tbody>
                    <tr *ngFor="let record of records; let even = even; let odd = odd" 
                        [ngStyle]="{'background-color': record.backgroundColor}" [ngClass]="{'even-row': even, 'odd-row': odd}">
                        <td class="record-attribute" [ngClass]="{'even-item': even, 'odd-item': odd}">{{getFormalGameTypeName(record.gameId)}}</td>
                        <td class="record-attribute" [ngClass]="{'even-item': even, 'odd-item': odd}">{{convertToDifficultyString(record.difficulty)}}</td>
                        <td class="record-attribute" [ngClass]="{'even-item': even, 'odd-item': odd}">{{record.score}}</td>
                        <td class="record-attribute" [ngClass]="{'even-item': even, 'odd-item': odd}">{{record.accuracy}}%</td>
                        <td class="record-attribute" [ngClass]="{'even-item': even, 'odd-item': odd}">{{pipeDate(record.timestamp)}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        
    </div>
</div>

