<div class="app-parent-container">
    <div class="main-container margin-top">
        <div class="logo-container margin-top-icon">
            <img src="/assets/icons/BeatTheory-banner.png" />
            
        </div>
        <div *ngIf="!updateSuccessful">
            <div class="password-reset-parent-container">
                <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
                    <div class="password-reset-container">
                        <label>New Password:</label>
                        <input type="password" id="password" formControlName="password"/>
                
                        <label>Verify New Password:</label>
                        <input type="password" id="verifyPassword" formControlName="verifyPassword"/>
            
                        <button type="submit" [disabled]="resetPasswordForm.invalid">Reset password</button>
                    </div>
                </form>
            </div>
            <div class="logo-container password-reset-parent-container">
                <div>
                    <app-spinner *ngIf="updatingPassword"></app-spinner>
                </div>
                <p *ngIf="passwordResetError_passwordIsInvalid" class="auth-error-message auth-error-message-left">
                    Password must contain at least one uppercase character and one special character.
                </p>
                <p *ngIf="passwordResetError_passwordsDoNotMatch" class="auth-error-message">
                    The two password fields do not match.
                </p>
                <p *ngIf="passwordResetError_passwordFailedToUpdate" class="auth-error-message auth-error-message-left">
                    There was an error with updating your password.  Please try again later or request a new link.
                </p>
            </div>
        </div>   
        <div *ngIf="updateSuccessful">
            <div class="auth-field-container">
                <p><b>Your password has been updated!</b></p>
               
            </div>
        </div>
        
            
    
    </div>
    
</div>
