import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { StaffGameHandlerComponent } from '../../staff-game-handler/staff-game-handler.component';
import { GameId } from '../../../constants/GameIdEnum';
import { StaffNote } from '../../../staff-graphics/models/StaffNote';
import { Staff } from '../../../staff-graphics/models/Staff';
import { StaffBuilder } from '../../../staff-graphics/StaffBuilder';
import { MatDialog } from '@angular/material/dialog';
import { SharedDataService } from '../../../util/SharedDataService';
import { ActivatedRoute, Router } from '@angular/router';
import { Note } from '../../../models/Note';


@Component({
  selector: 'app-staff-identification-note-game',
  standalone: true,
  imports: [CommonModule,],
  templateUrl: './../../staff-game-handler/staff-game-handler.component.html',
  styleUrls: ['./../../staff-game-handler/staff-game-handler.component.css', "./staff-identification-note-game.component.css",
              './../../game-handler.component.css']
})
export class StaffIdentificationNoteGameComponent extends StaffGameHandlerComponent implements OnInit, OnDestroy {
    public override gameId = GameId.StaffIdentification_Notes;
    override initialCountdown: number = 300;
    private staff: Staff = new Staff([4, 4], []);


    constructor(protected override router: Router, protected override sharedDataService: SharedDataService, protected override dialog: MatDialog, 
                protected override route: ActivatedRoute, @Inject(PLATFORM_ID) private platformId: Object, private cdr: ChangeDetectorRef){
        super(router, sharedDataService, dialog, route);
    }
    

    override ngOnInit(): void {
        this.initModule();
    }
    
    //Runs after view has initialized
    async ngAfterViewInit(){
        if(isPlatformBrowser(this.platformId)){
            this.setCanvasSize(); 

            const note: Note = this.generateRandomNote(this.isTrebleClef);
            this.randomElement = new StaffNote(note, this.canvas.nativeElement);
            this.builder = new StaffBuilder(this.staff, this.canvas.nativeElement, this.gameId);
            await this.builder.loadFont();
            this.builder.drawSimpleStaff(this.randomElement, this.isTrebleClef);
            //Reload after NotoMusic font loads
            setTimeout(async () =>{
                this.canvas.nativeElement.getContext('2d')?.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
                await this.builder.drawSimpleStaff(this.randomElement, this.isTrebleClef);
            }, 700);


        }

        
    }

    protected override check(guess: number | string, buttonRef: HTMLButtonElement){
        this.checkAnswer(guess, buttonRef);
    }

}
