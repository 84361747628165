import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, Renderer2} from '@angular/core';
import { FormGroup, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import {} from '@angular/common/http';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { TooltipComponent } from "../tooltip/tooltip.component";
import { MatTooltipModule } from '@angular/material/tooltip';
import { NavigationStart, Router } from '@angular/router';
import { SharedDataService } from '../util/SharedDataService';
import { Clef } from '../constants/DifficultyEnum';
import { RoutingUtil } from '../util/RoutingUtil';
import { GameId } from '../constants/GameIdEnum';
import { CommonUtil } from '../util/CommonUtil';
import { AuthenticationService } from '../auth/service/AuthenticationService';
import { Subject } from 'rxjs';
import { SpinnerComponent } from '../graphics/spinner/spinner.component';

@Component({
  selector: 'app-difficulty-selector',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatTooltipModule, SpinnerComponent],
  templateUrl: './difficulty-selector.component.html',
  styleUrl: './difficulty-selector.component.css'
})
export class DifficultySelectorComponent implements OnInit{
    protected gameId: number = 0;
    protected title: string = "";
    protected subtitle: string = "";
    protected description: string = "";
    protected description_practiceMode : string = "";
    protected description_challengeMode: string = "";
    protected easyButtonTooltip: string = "";
    protected mediumButtonTootip: string = "";
    protected hardButtonTooltip: string = "";
    protected isHovered_easy: boolean = false;
    protected isHovered_medium: boolean = false;
    protected isHovered_hard: boolean = false;
    protected isHovered_arpeggiated: boolean = false;
    protected isHovered_block: boolean = false;
    protected isHovered_treble: boolean = false;
    protected isHovered_bass: boolean = false;
    protected practiceModeSelected: boolean = true;
    protected challengeModeSelected: boolean = false;
    protected isHovered: boolean = false;
    protected difficulty: string = "1";
    protected incompatible: boolean = false;

    //loading indicator
    protected rendered: boolean = false;

    //For navigating back to home page
    private backNavigationSubject: Subject<void> = new Subject<void>();

    constructor(protected router: Router, protected sharedDataService: SharedDataService, protected el: ElementRef, 
                protected authService: AuthenticationService, @Inject(PLATFORM_ID) protected platformId: Object,
                protected renderer: Renderer2){
        if(this.gameId == GameId.StaffIdentification_Notes && this.authService.getDifficultySelection() === "2"){
            this.authService.setDifficultySelection("1");
        }
        if(!CommonUtil.isEmpty(this.authService.getGameMode() as string)){
            this.challengeModeSelected = this.authService.getGameMode() === "1" ? true : false; 
            this.practiceModeSelected = !this.challengeModeSelected;
        }

        // this.rendered = true;


    
    }

    //Configure user to always return to home screen when back button is clicked
    async ngOnInit(): Promise<void>{
        if (isPlatformBrowser(this.platformId)) {
            await CommonUtil.loadFont();
            this.router.events.subscribe(event => {
                if (event instanceof NavigationStart) {
                    if (window.history.state && window.history.state.navigationId) {
                        // Check if navigationId is present to identify back/forward navigation
                        const isBackNavigation = event.navigationTrigger === 'popstate';
          
                        if (isBackNavigation) {
                            this.router.navigate(['/']);
                        }
                    }
                }
            });

            
            //Change background color dynamically depending on game type
            this.changeBackgroundColor();
            this.rendered = true;

             // Check if window is defined
            if (typeof window !== 'undefined') {
                // Initialize screen size
                this.updateScreenSize();
    
                // Add event listener for window resize
                window.addEventListener('resize', this.updateScreenSize.bind(this));
            } else {
                console.log('undefined');
            }
        }
        
    }

    onBackNavigation(callback: () => void): void {
        this.backNavigationSubject.subscribe(callback);
    }

    protected difficultyForm = new FormGroup({
        difficulty: new FormControl<string | null>(this.authService.getDifficultySelection() === null ? "1" : this.authService.getDifficultySelection(), Validators.required),
        clef: new FormControl<string | null>(Clef.Treble.toString()) 
    });

    protected selectDifficulty(level: string) {
        this.authService.setDifficultySelection(level);
        this.difficultyForm.controls.difficulty.setValue(level);
    }

    protected selectClef(clef: string){
        this.difficultyForm.controls.clef.setValue(clef);
    }

    protected selectTab(id: string){
        //Makes sure that hover coloring does not persist
        this.isHovered = false;

        if(id === 'left'){
            this.practiceModeSelected = true;
            this.challengeModeSelected = false;
        } else { 
            this.challengeModeSelected = true;
            this.practiceModeSelected = false;
        }

        this.authService.setGameMode(this.challengeModeSelected ? "1" : "0");

    }

    protected onMouseEnter(id: string): void {
        const element = this.el.nativeElement.ownerDocument.getElementById(id);
        if (element) {
            //Check for either selected tab so that they are not given hover
            if ((element.classList.contains('tab-cell-left-selected') && id === 'left-cell') ||
                (element.classList.contains('tab-cell-right-selected') && id === 'right-cell')) {
                    this.isHovered = false;
                    return;
            }
        }
        this.isHovered = true;
    }

    protected onMouseLeave(): void {
        this.isHovered = false;
    }
    
    //Submit handler
    protected onSubmit() {
        const difficulty: string = this.difficultyForm.controls.difficulty.value as string;
        const clef: string = this.difficultyForm.controls.clef.value as string;
        let payload = {};

        //Include clef with staff identification games
        if(this.gameId === 6 || this.gameId === 7){
            payload = {
                difficulty: difficulty,
                clef: clef,
                practiceModeSelected: this.practiceModeSelected,
                challengeModeSelected: this.challengeModeSelected
            }
        } else{
            payload = {
                difficulty: difficulty,
                practiceModeSelected: this.practiceModeSelected,
                challengeModeSelected: this.challengeModeSelected
            }
        }
        
        this.sharedDataService.updateFormData(payload);
        RoutingUtil.navigateToGame(this.gameId, this.router);
    }

    //Gets the background color of the container
    public getBackgroundColor(): string{
        return CommonUtil.getBackgroundColor(this.gameId);
    }

    //Checks for the current game id range
    public checkForCurrentGameId(): string{
        if(this.gameId == GameId.EarTraining_Chords || this.gameId == GameId.EarTraining_Intervals ||
            this.gameId == GameId.EarTraining_Scales){
                return 'ET';
        } else if(this.gameId == GameId.StaffIdentification_Chords || this.gameId == GameId.StaffIdentification_Intervals ||
            this.gameId == GameId.StaffIdentification_Notes || this.gameId == GameId.StaffIdentification_Scales){
                return 'SI';
        } else if(this.gameId == GameId.StaffBuilding_Chords || this.gameId == GameId.StaffBuilding_Intervals ||
            this.gameId == GameId.StaffBuilding_Scales){
                return 'SB';
        }
        
        return '';
    }

    //Change background color based on link hover
    public changeBackgroundColor(): void{
        // const elementId: string = (event.target as HTMLElement).id;
        switch(this.checkForCurrentGameId()){
            case 'ET':
                CommonUtil.addNewBackgroundColorClass('background-color-primary', this.renderer);
                break;
            case 'SI':
                CommonUtil.addNewBackgroundColorClass('background-color-secondary', this.renderer);
                break;
            case 'SB':
                CommonUtil.addNewBackgroundColorClass('background-color-tertiary', this.renderer);
                break;
        }
    }


    //Disable scale staff games if not on desktop device
    private updateScreenSize(){
        const width: number = window.innerWidth;


        // if(width <= 1024 && (this.gameId == GameId.StaffBuilding_Scales || this.gameId == GameId.StaffIdentification_Scales ||
        //     this.gameId == GameId.StaffBuilding_Intervals || this.gameId == GameId.StaffBuilding_Chords || this.gameId == GameId.StaffIdentification_Chords
        //     || this.gameId == GameId.StaffIdentification_Intervals
        // )){
        //     this.incompatible = true;
        // } else {
        //     this.incompatible = false;
        // }
    }

}
