<div class="app-parent-container">
    <div class="main-container margin-top">
        <div class="logo-container margin-top-icon">
            <img src="/assets/icons/BeatTheory-banner.png" />
         </div>
        <div class="form-container auth-form-container">
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="login-form">
                <div class="auth-control-container">
                    
                    <div *ngIf="loginFailed_notRegistered" class="login-error-message-container">
                        <div>
                            <app-spinner *ngIf="isSendingVerificationEmail"></app-spinner>
                        </div>
                        <div style="margin: 1.5em" *ngIf="sentNewVerificationEmail && !isSendingVerificationEmail">
                            <p class="auth-error-message auth-error-message-left">Your account has not been verified.  Please click on the link sent to your email to finish verifying your account. 
                                <a (click)="resendVerificationEmail()" style="color: #0000EE; cursor:pointer"> Click here to resend.</a></p>
                            <!-- <button *ngIf="loginFailed_notRegistered" class="link-button" (click)="resendVerificationEmail()">Click here to resend</button> -->
                        </div>
                        <div style="margin: 1.5em" *ngIf="!sentNewVerificationEmail">
                            <p *ngIf="loginFailed_verificationEmailFailedToSend" class="auth-error-message auth-error-message-left">There was an error with sending the verification email.  Please contact our support team for assistance if 
                                this issue persists.</p>
                            <p *ngIf="!loginFailed_verificationEmailFailedToSend" class="auth-info-message">Registration email sent.</p>
                        </div>
                    </div>
                    <div class="auth-field-container">
                        <label for="email">Email</label>
                        <input type="text" id="email" formControlName="email" 
                        [ngClass]="{'auth-input-error': loginFailed_noUserFound, 'auth-input': !loginFailed_noUserFound}" >
                        <div *ngIf="loginFailed_noUserFound" class="auth-error-message auth-error-message-left">No user was found for that email</div>
                    </div>
                    
    
                    <div class="auth-field-container">
                        <label for="Password">Password</label>
                        <input type="password" id="password" formControlName="password"
                        [ngClass]="{'auth-input-error': loginFailed_incorrectPassword, 'auth-input': !loginFailed_incorrectPassword}">
                        <div *ngIf="loginFailed_incorrectPassword" class="auth-error-message auth-error-message-left">Incorrect password</div>
                    </div>
                    
    
    
                    <div class="auth-field-container">
                        <button type="submit" [disabled]="loginForm.invalid">Log in</button>
                    </div>
    
                    <div *ngIf="loginFailed_serverDown" class="auth-field-container">
                        <div class="auth-error-message auth-error-message-left">The login server is currently down.  Please try again later.</div>
                    </div>
    
    
                </div>
                
            </form>
            
            <div *ngIf="isSubmitting">
                <app-spinner></app-spinner>
            </div>
            <div class="registration-container">
                <p *ngIf="!isSubmitting">Don't have an account? <a href="/register">Sign up here</a></p>
                <p *ngIf="!isSubmitting"><a href="/reset-password">Forgot password?</a></p>
            </div>
        </div>
    
        
    </div>
</div>
