import { Note } from "./Note";
import { IntervalQuality } from "../constants/IntervalQualityConstants";

export class Interval{
    private note1: Note;
    private note2: Note;
    private quality: IntervalQuality;
    private notes: Note[] = [];

    constructor(note1: Note, semitones: number, clef: boolean, note2?: Note){
        this.note1 = note1;

        if((note2 === undefined || note2 === null)){
            this.quality = this.getIntervalQuality(semitones);
            this.note2 = this.getNextIntervalNote(clef);  
            
        } else {
            this.note2 = note2;
            this.quality = this.getIntervalQualityFromFrequency();
        }

        this.notes.push(this.note1);
        this.notes.push(this.note2);
    }

    private getIntervalQuality(semitones: number): IntervalQuality{
        switch(semitones){
            case 1:
                return IntervalQuality.MINOR_SECOND;
            case 2:
                return IntervalQuality.MAJOR_SECOND;
            case 3:
                return IntervalQuality.MINOR_THIRD;
            case 4:
                return IntervalQuality.MAJOR_THIRD;
            case 5:
                return IntervalQuality.PERFECT_FOURTH;
            case 6:
                return IntervalQuality.TRITONE;
            case 7:
                return IntervalQuality.PERFECT_FIFTH;
            case 8:
                return IntervalQuality.MINOR_SIXTH;
            case 9:
                return IntervalQuality.MAJOR_SIXTH;
            case 10:
                return IntervalQuality.MINOR_SEVENTH;
            case 11:
                return IntervalQuality.MAJOR_SEVENTH;
            case 12:
                return IntervalQuality.OCTAVE;
            default:
                return IntervalQuality.PERFECT_FIFTH;
        }
    }
    //Returns the interval quality index based on ratio of the two note frequencies
    private getIntervalQualityFromFrequency(): IntervalQuality{
        const ratio: string= (this.note2.getFrequency / this.note1.getFrequency).toFixed(2);
        let quality: IntervalQuality = IntervalQuality.NULL;
        switch(ratio){
            case "1.06":
                quality = IntervalQuality.MINOR_SECOND;
                break;
            case "1.12":
                quality = IntervalQuality.MAJOR_SECOND;
                break;
            case "1.19":
                quality = IntervalQuality.MINOR_THIRD;
                break;
            case "1.26":
                quality = IntervalQuality.MAJOR_THIRD;
                break;
            case "1.33":
                quality = IntervalQuality.PERFECT_FOURTH;
                break;
            case "1.41":
                quality = IntervalQuality.TRITONE;
                break;
            case "1.50":
                quality = IntervalQuality.PERFECT_FIFTH;
                break;
            case "1.59":
                quality = IntervalQuality.MINOR_SIXTH;
                break;
            case "1.68":
                quality = IntervalQuality.MAJOR_SIXTH;
                break;
            case "1.78":
                quality = IntervalQuality.MINOR_SEVENTH;
                break;
            case "1.89":
                quality = IntervalQuality.MAJOR_SEVENTH;
                break;
            case "2.00":
                quality = IntervalQuality.OCTAVE;
                break;
            default:
                quality =  IntervalQuality.NULL;
                break;
        }   
        return quality;
    }

    //Returns the number of semitones between two notes
    public getNumberOfSteps(): number{
        return this.quality.valueOf();
    }

    get getNote1(): Note{
        return this.note1;
    }

    set setNote1(note: Note){
        this.note1 = note;
    }

    get getNote2(): Note{
        return this.note2;
    }

    set setNote2(note: Note){
        this.note2 = note;
    }

    get getQuality(): IntervalQuality{
        return this.quality;
    }

    set setQuality(quality: IntervalQuality){
        this.quality = quality;
    }

    //Gets the next interval note depending on the root note and interval quality
    private getNextIntervalNote(clef: boolean): Note{
        const root: string = this.note1.getRoot;
        let octave: number = this.note1.getOctave;
        let noteName: string = "";
        
        switch(root){
            case "C":
                if(this.quality == IntervalQuality.MINOR_SECOND){
                    noteName = "Db";
                } else if(this.quality == IntervalQuality.MAJOR_SECOND){
                    noteName = "D";
                } else if(this.quality == IntervalQuality.MINOR_THIRD){
                    noteName = "Eb";
                } else if(this.quality == IntervalQuality.MAJOR_THIRD){
                    noteName = "E";
                } else if(this.quality == IntervalQuality.PERFECT_FOURTH){
                    noteName = "F";
                } else if(this.quality == IntervalQuality.TRITONE){
                    noteName = "F#";
                } else if(this.quality == IntervalQuality.PERFECT_FIFTH){
                    noteName = "G";
                } else if(this.quality == IntervalQuality.MINOR_SIXTH){
                    noteName = "Ab";
                } else if(this.quality == IntervalQuality.MAJOR_SIXTH){
                    noteName = "A";
                } else if(this.quality == IntervalQuality.MINOR_SEVENTH){
                    noteName = "Bb";
                } else if(this.quality == IntervalQuality.MAJOR_SEVENTH){
                    noteName = "B";
                } else if(this.quality == IntervalQuality.OCTAVE){
                    noteName = "C";
                    octave += 1;
                }
                break;
            case "G":
                if(this.quality == IntervalQuality.MINOR_SECOND){
                    noteName = "Ab";
                } else if(this.quality == IntervalQuality.MAJOR_SECOND){
                    noteName = "A";
                } else if(this.quality == IntervalQuality.MINOR_THIRD){
                    noteName = "Bb";
                } else if(this.quality == IntervalQuality.MAJOR_THIRD){
                    noteName = "B";
                } else if(this.quality == IntervalQuality.PERFECT_FOURTH){
                    noteName = "C";
                    octave += 1;
                } else if(this.quality == IntervalQuality.TRITONE){
                    noteName = "C#";
                    octave += 1;
                } else if(this.quality == IntervalQuality.PERFECT_FIFTH){
                    noteName = "D";
                    octave += 1;
                } else if(this.quality == IntervalQuality.MINOR_SIXTH){
                    noteName = "Eb";
                    octave += 1;
                } else if(this.quality == IntervalQuality.MAJOR_SIXTH){
                    noteName = "E";
                    octave += 1;
                } else if(this.quality == IntervalQuality.MINOR_SEVENTH){
                    noteName = "F";
                    octave += 1;
                } else if(this.quality == IntervalQuality.MAJOR_SEVENTH){
                    noteName = "F#";
                    octave += 1;
                } else if(this.quality == IntervalQuality.OCTAVE){
                    noteName = "G";
                    octave += 1;
                }
                break;
            case "D":
                if(this.quality == IntervalQuality.MINOR_SECOND){
                    noteName = "Eb";
                } else if(this.quality == IntervalQuality.MAJOR_SECOND){
                    noteName = "E";
                } else if(this.quality == IntervalQuality.MINOR_THIRD){
                    noteName = "F";
                } else if(this.quality == IntervalQuality.MAJOR_THIRD){
                    noteName = "F#";
                } else if(this.quality == IntervalQuality.PERFECT_FOURTH){
                    noteName = "G";
                } else if(this.quality == IntervalQuality.TRITONE){
                    noteName = "G#";
                } else if(this.quality == IntervalQuality.PERFECT_FIFTH){
                    noteName = "A";
                } else if(this.quality == IntervalQuality.MINOR_SIXTH){
                    noteName = "Bb";
                } else if(this.quality == IntervalQuality.MAJOR_SIXTH){
                    noteName = "B";
                } else if(this.quality == IntervalQuality.MINOR_SEVENTH){
                    noteName = "C";
                    octave += 1;
                } else if(this.quality == IntervalQuality.MAJOR_SEVENTH){
                    noteName = "C#";
                    octave += 1;
                } else if(this.quality == IntervalQuality.OCTAVE){
                    noteName = "D";
                    octave += 1;
                }
                break;
            case "A":
                if(this.quality == IntervalQuality.MINOR_SECOND){
                    noteName = "Bb";
                } else if(this.quality == IntervalQuality.MAJOR_SECOND){
                    noteName = "B";
                } else if(this.quality == IntervalQuality.MINOR_THIRD){
                    noteName = "C";
                    octave += 1;
                } else if(this.quality == IntervalQuality.MAJOR_THIRD){
                    noteName = "C#";
                    octave += 1;
                } else if(this.quality == IntervalQuality.PERFECT_FOURTH){
                    noteName = "D";
                    octave += 1;
                } else if(this.quality == IntervalQuality.TRITONE){
                    noteName = "D#";
                    octave += 1;
                } else if(this.quality == IntervalQuality.PERFECT_FIFTH){
                    noteName = "E";
                    octave += 1;
                } else if(this.quality == IntervalQuality.MINOR_SIXTH){
                    noteName = "F";
                    octave += 1;
                } else if(this.quality == IntervalQuality.MAJOR_SIXTH){
                    noteName = "F#";
                    octave += 1;
                } else if(this.quality == IntervalQuality.MINOR_SEVENTH){
                    noteName = "G";
                    octave += 1;
                } else if(this.quality == IntervalQuality.MAJOR_SEVENTH){
                    noteName = "G#";
                    octave += 1;
                } else if(this.quality == IntervalQuality.OCTAVE){
                    noteName = "A";
                    octave += 1;
                }
                break;
            case "E":
                if(this.quality == IntervalQuality.MINOR_SECOND){
                    noteName = "F";
                } else if(this.quality == IntervalQuality.MAJOR_SECOND){
                    noteName = "F#";
                } else if(this.quality == IntervalQuality.MINOR_THIRD){
                    noteName = "G";
                } else if(this.quality == IntervalQuality.MAJOR_THIRD){
                    noteName = "G#";
                } else if(this.quality == IntervalQuality.PERFECT_FOURTH){
                    noteName = "A";
                } else if(this.quality == IntervalQuality.TRITONE){
                    noteName = "A#";
                } else if(this.quality == IntervalQuality.PERFECT_FIFTH){
                    noteName = "B";
                } else if(this.quality == IntervalQuality.MINOR_SIXTH){
                    noteName = "C";
                    octave += 1;
                } else if(this.quality == IntervalQuality.MAJOR_SIXTH){
                    noteName = "C#";
                    octave += 1;
                } else if(this.quality == IntervalQuality.MINOR_SEVENTH){
                    noteName = "D";
                    octave += 1;
                } else if(this.quality == IntervalQuality.MAJOR_SEVENTH){
                    noteName = "D#";
                    octave += 1;
                } else if(this.quality == IntervalQuality.OCTAVE){
                    noteName = "E";
                    octave += 1;
                }
                break;
            case "B":
                if(this.quality == IntervalQuality.MINOR_SECOND){
                    noteName = "C";
                    octave += 1;
                } else if(this.quality == IntervalQuality.MAJOR_SECOND){
                    noteName = "C#";
                    octave += 1;
                } else if(this.quality == IntervalQuality.MINOR_THIRD){
                    noteName = "D";
                    octave += 1;
                } else if(this.quality == IntervalQuality.MAJOR_THIRD){
                    noteName = "D#";
                    octave += 1;
                } else if(this.quality == IntervalQuality.PERFECT_FOURTH){
                    noteName = "E";
                    octave += 1;
                } else if(this.quality == IntervalQuality.TRITONE){
                    noteName = "F";
                    octave += 1;
                } else if(this.quality == IntervalQuality.PERFECT_FIFTH){
                    noteName = "F#";
                    octave += 1;
                } else if(this.quality == IntervalQuality.MINOR_SIXTH){
                    noteName = "G";
                    octave += 1;
                } else if(this.quality == IntervalQuality.MAJOR_SIXTH){
                    noteName = "G#";
                    octave += 1;
                } else if(this.quality == IntervalQuality.MINOR_SEVENTH){
                    noteName = "A";
                    octave += 1;
                } else if(this.quality == IntervalQuality.MAJOR_SEVENTH){
                    noteName = "A#";
                    octave += 1;
                } else if(this.quality == IntervalQuality.OCTAVE){
                    noteName = "B";
                    octave += 1;
                }
                break;
            case "F#":
                if(this.quality == IntervalQuality.MINOR_SECOND){
                    noteName = "G";
                } else if(this.quality == IntervalQuality.MAJOR_SECOND){
                    noteName = "G#";
                } else if(this.quality == IntervalQuality.MINOR_THIRD){
                    noteName = "A";
                } else if(this.quality == IntervalQuality.MAJOR_THIRD){
                    noteName = "A#";
                } else if(this.quality == IntervalQuality.PERFECT_FOURTH){
                    noteName = "B";
                } else if(this.quality == IntervalQuality.TRITONE){
                    noteName = "C";
                    octave += 1;
                } else if(this.quality == IntervalQuality.PERFECT_FIFTH){
                    noteName = "C#";
                    octave += 1;
                } else if(this.quality == IntervalQuality.MINOR_SIXTH){
                    noteName = "D";
                    octave += 1;
                } else if(this.quality == IntervalQuality.MAJOR_SIXTH){
                    noteName = "D#";
                    octave += 1;
                } else if(this.quality == IntervalQuality.MINOR_SEVENTH){
                    noteName = "E";
                    octave += 1;
                } else if(this.quality == IntervalQuality.MAJOR_SEVENTH){
                    noteName = "E#";
                    octave += 1;
                } else if(this.quality == IntervalQuality.OCTAVE){
                    noteName = "F#";
                    octave += 1;
                }
                break;
            case "C#":
                if(this.quality == IntervalQuality.MINOR_SECOND){
                    noteName = "D";
                } else if(this.quality == IntervalQuality.MAJOR_SECOND){
                    noteName = "D#";
                } else if(this.quality == IntervalQuality.MINOR_THIRD){
                    noteName = "E";
                } else if(this.quality == IntervalQuality.MAJOR_THIRD){
                    noteName = "E#";
                } else if(this.quality == IntervalQuality.PERFECT_FOURTH){
                    noteName = "F#";
                } else if(this.quality == IntervalQuality.TRITONE){
                    noteName = "G";
                } else if(this.quality == IntervalQuality.PERFECT_FIFTH){
                    noteName = "G#";
                } else if(this.quality == IntervalQuality.MINOR_SIXTH){
                    noteName = "A";
                } else if(this.quality == IntervalQuality.MAJOR_SIXTH){
                    noteName = "A#";
                } else if(this.quality == IntervalQuality.MINOR_SEVENTH){
                    noteName = "B";
                } else if(this.quality == IntervalQuality.MAJOR_SEVENTH){
                    noteName = "B#";
                } else if(this.quality == IntervalQuality.OCTAVE){
                    noteName = "C#";
                    octave += 1;
                }
                break;
            case "F":
                if(this.quality == IntervalQuality.MINOR_SECOND){
                    noteName = "Gb";
                } else if(this.quality == IntervalQuality.MAJOR_SECOND){
                    noteName = "G";
                } else if(this.quality == IntervalQuality.MINOR_THIRD){
                    noteName = "Ab";
                } else if(this.quality == IntervalQuality.MAJOR_THIRD){
                    noteName = "A";
                } else if(this.quality == IntervalQuality.PERFECT_FOURTH){
                    noteName = "Bb";
                } else if(this.quality == IntervalQuality.TRITONE){
                    noteName = "B";
                } else if(this.quality == IntervalQuality.PERFECT_FIFTH){
                    noteName = "C";
                    octave += 1;
                } else if(this.quality == IntervalQuality.MINOR_SIXTH){
                    noteName = "Db";
                    octave += 1;
                } else if(this.quality == IntervalQuality.MAJOR_SIXTH){
                    noteName = "D";
                    octave += 1;
                } else if(this.quality == IntervalQuality.MINOR_SEVENTH){
                    noteName = "Eb";
                    octave += 1;
                } else if(this.quality == IntervalQuality.MAJOR_SEVENTH){
                    noteName = "E";
                    octave += 1;
                } else if(this.quality == IntervalQuality.OCTAVE){
                    noteName = "F";
                    octave += 1;
                }
                break;
            case "Bb":
                if(this.quality == IntervalQuality.MINOR_SECOND){
                    noteName = "Cb";
                    octave += 1;
                } else if(this.quality == IntervalQuality.MAJOR_SECOND){
                    noteName = "C";
                    octave += 1;
                } else if(this.quality == IntervalQuality.MINOR_THIRD){
                    noteName = "Db";
                    octave += 1;
                } else if(this.quality == IntervalQuality.MAJOR_THIRD){
                    noteName = "D";
                    octave += 1;
                } else if(this.quality == IntervalQuality.PERFECT_FOURTH){
                    noteName = "Eb";
                    octave += 1;
                } else if(this.quality == IntervalQuality.TRITONE){
                    noteName = "E";
                    octave += 1;
                } else if(this.quality == IntervalQuality.PERFECT_FIFTH){
                    noteName = "F";
                    octave += 1;
                } else if(this.quality == IntervalQuality.MINOR_SIXTH){
                    noteName = "Gb";
                    octave += 1;
                } else if(this.quality == IntervalQuality.MAJOR_SIXTH){
                    noteName = "G";
                    octave += 1;
                } else if(this.quality == IntervalQuality.MINOR_SEVENTH){
                    noteName = "Ab";
                    octave += 1;
                } else if(this.quality == IntervalQuality.MAJOR_SEVENTH){
                    noteName = "A";
                    octave += 1;
                } else if(this.quality == IntervalQuality.OCTAVE){
                    noteName = "Bb";
                    octave += 1;
                }
                break;
            case "Eb":
                if(this.quality == IntervalQuality.MINOR_SECOND){
                    noteName = "Fb";
                } else if(this.quality == IntervalQuality.MAJOR_SECOND){
                    noteName = "F";
                } else if(this.quality == IntervalQuality.MINOR_THIRD){
                    noteName = "Gb";
                } else if(this.quality == IntervalQuality.MAJOR_THIRD){
                    noteName = "G";
                } else if(this.quality == IntervalQuality.PERFECT_FOURTH){
                    noteName = "Ab";
                } else if(this.quality == IntervalQuality.TRITONE){
                    noteName = "A";
                } else if(this.quality == IntervalQuality.PERFECT_FIFTH){
                    noteName = "Bb";
                } else if(this.quality == IntervalQuality.MINOR_SIXTH){
                    noteName = "Cb";
                    octave += 1;
                } else if(this.quality == IntervalQuality.MAJOR_SIXTH){
                    noteName = "C";
                    octave += 1;
                } else if(this.quality == IntervalQuality.MINOR_SEVENTH){
                    noteName = "Db";
                    octave += 1;
                } else if(this.quality == IntervalQuality.MAJOR_SEVENTH){
                    noteName = "D";
                    octave += 1;
                } else if(this.quality == IntervalQuality.OCTAVE){
                    noteName = "Eb";
                    octave += 1;
                }
                break;
            case "Ab":
                if(this.quality == IntervalQuality.MINOR_SECOND){
                    noteName = "Bbb";
                } else if(this.quality == IntervalQuality.MAJOR_SECOND){
                    noteName = "Bb";
                } else if(this.quality == IntervalQuality.MINOR_THIRD){
                    noteName = "Cb";
                    octave += 1;
                } else if(this.quality == IntervalQuality.MAJOR_THIRD){
                    noteName = "C";
                    octave += 1;
                } else if(this.quality == IntervalQuality.PERFECT_FOURTH){
                    noteName = "Db";
                    octave += 1;
                } else if(this.quality == IntervalQuality.TRITONE){
                    noteName = "D";
                    octave += 1;
                } else if(this.quality == IntervalQuality.PERFECT_FIFTH){
                    noteName = "Eb";
                    octave += 1;
                } else if(this.quality == IntervalQuality.MINOR_SIXTH){
                    noteName = "Fb";
                    octave += 1;
                } else if(this.quality == IntervalQuality.MAJOR_SIXTH){
                    noteName = "F";
                    octave += 1;
                } else if(this.quality == IntervalQuality.MINOR_SEVENTH){
                    noteName = "Gb";
                    octave += 1;
                } else if(this.quality == IntervalQuality.MAJOR_SEVENTH){
                    noteName = "G";
                    octave += 1;
                } else if(this.quality == IntervalQuality.OCTAVE){
                    noteName = "Ab";
                    octave += 1;
                }
                break;
            case "Db":
                if(this.quality == IntervalQuality.MINOR_SECOND){
                    noteName = "Ebb";
                } else if(this.quality == IntervalQuality.MAJOR_SECOND){
                    noteName = "Eb";
                } else if(this.quality == IntervalQuality.MINOR_THIRD){
                    noteName = "Fb";
                } else if(this.quality == IntervalQuality.MAJOR_THIRD){
                    noteName = "F";
                } else if(this.quality == IntervalQuality.PERFECT_FOURTH){
                    noteName = "Gb";
                } else if(this.quality == IntervalQuality.TRITONE){
                    noteName = "G";
                } else if(this.quality == IntervalQuality.PERFECT_FIFTH){
                    noteName = "Ab";
                } else if(this.quality == IntervalQuality.MINOR_SIXTH){
                    noteName = "Bbb";
                } else if(this.quality == IntervalQuality.MAJOR_SIXTH){
                    noteName = "Bb";
                } else if(this.quality == IntervalQuality.MINOR_SEVENTH){
                    noteName = "Cb";
                    octave += 1;
                } else if(this.quality == IntervalQuality.MAJOR_SEVENTH){
                    noteName = "C";
                    octave += 1;
                } else if(this.quality == IntervalQuality.OCTAVE){
                    noteName = "Db";
                    octave += 1;
                }
                break;
        }

        noteName += octave;
        return new Note(noteName, clef);
    }

    get getNotes(): Note[]{
        return this.notes;
    }

}