import * as Tone from 'tone';
import { Chord } from "../models/Chord";
import { Note } from '../models/Note'; 

//Utility class for handling Tone.js sampler metho ds, particularly related to playback functionality
export class PianoUtil{
    private static piano: Tone.Sampler;
    constructor(){}

    private static audioContext: AudioContext;
    private static activeBufferSources: AudioBufferSourceNode[] = [];

    //Preload audio buffers and map them to their respective URLS
    private static preloadBuffers = async (notes: Note[]): Promise<Map<string, AudioBuffer>> => {
        const bufferMap = new Map<string, AudioBuffer>();
        for(const note of notes){
            const response: Response = await fetch(note.getAudioPath);
            const arrayBuffer: ArrayBuffer = await response.arrayBuffer();
            let audioBuffer: AudioBuffer | null = null;

            try{
                audioBuffer = await this.audioContext.decodeAudioData(arrayBuffer);
                bufferMap.set(note.getAudioPath, audioBuffer);
            } catch(error){
                console.error(error);
                
                const silentBuffer = this.audioContext.createBuffer(1, this.audioContext.sampleRate, this.audioContext.sampleRate);
                bufferMap.set(note.getAudioPath, silentBuffer);
            }
        }        
        return bufferMap;
    };

    //Plays the note samples of a chord simultaneously
    public static async playChord(chord: Chord): Promise<number>{
        let res: number = 0;
        let chordType: number = chord.getQuality;

        //Omit the fifth and ninth for 13th chords
        if(chordType == 55 || chordType == 60 || chordType == 65 || chordType == 70){
            const newNoteArray: Note[] = this.omitNotes([2], chord);
            chord.setNotes = newNoteArray;
        }
        if(chordType == 75 || chordType == 80 || chordType == 85){
            const newNoteArray: Note[] = this.omitNotes([2, 4], chord);
            chord.setNotes = newNoteArray;
        }

        //Prepare audio context for playback
        this.initPlayback();

        const notes: Note[] = chord.getNotes;
        const bufferMap: Map<string, AudioBuffer> = await this.preloadBuffers(notes);
        const startTime: number = this.audioContext.currentTime + 0.1; // Schedule for synchronized playback
        chord.getNotes.forEach(note => {
            const bufferSource: AudioBufferSourceNode = this.audioContext.createBufferSource();
            bufferSource.buffer = bufferMap.get(note.getAudioPath) as AudioBuffer;
            bufferSource.connect(this.audioContext.destination);
            bufferSource.start(startTime);

            this.activeBufferSources.push(bufferSource);
            // Remove from active sources once playback ends
            bufferSource.onended = () => {
                this.removeSource(bufferSource);
            };

            res = bufferSource.buffer.duration;
        });

        return res * 1000;
    }

    // Stop and clear all active sources to ensure termination of audio playback
    public static stopAllActiveSources(): void {
        this.activeBufferSources.forEach(source => source.stop());
        this.activeBufferSources = [];
    }

    // Remove a source from the active sources list to ensure termination of audio playback
    private static removeSource(source: AudioBufferSourceNode): void {
        const index = this.activeBufferSources.indexOf(source);
        if (index !== -1) {
            this.activeBufferSources.splice(index, 1);
        }
    }
    

    //Helper method to omit chord from upper extension chords
    private static omitNotes(arr: number[], chord: Chord): Note[]{
        let newNoteArray: Note[] = [];
        for(let i = 0; i < chord.getNotes.length; i++){
            if(!arr.includes(i)){
                newNoteArray.push(chord.getNotes[i]);
            }
        }
        return newNoteArray;
    }

    //Release sampler attack
    public static releaseSamplerNotes(): void{
        if(this.piano !== undefined){
            this.piano.releaseAll();
        }
    }

    //Play two or more notes in succession
    public static async playNotes(notes: Note[], n: number): Promise<number>{
        // Prepare audio context for playback
        this.initPlayback();
        const bufferMap: Map<string, AudioBuffer> = await this.preloadBuffers(notes);
        let startTime: number = this.audioContext.currentTime + 0.1;
        let audioLength: number = 0;
        notes.forEach((note) => {
            const originalBuffer: AudioBuffer = bufferMap.get(note.getAudioPath) as AudioBuffer;
            const newDuration: number = originalBuffer.duration / n;
            audioLength += newDuration;
            // Create a new buffer with 1/n duration
            const slicedBuffer: AudioBuffer = this.audioContext.createBuffer(
                originalBuffer.numberOfChannels,
                this.audioContext.sampleRate * newDuration,
                this.audioContext.sampleRate
            );

            // Copy the audio data into the new buffer
            for (let channel = 0; channel < originalBuffer.numberOfChannels; channel++) {
                const originalChannelData = originalBuffer.getChannelData(channel);
                const slicedChannelData = slicedBuffer.getChannelData(channel);

                // Copy only the first quarter of the original buffer's data
                slicedChannelData.set(originalChannelData.slice(0, slicedChannelData.length));
            }

            // Create the buffer source
            const bufferSource: AudioBufferSourceNode = this.audioContext.createBufferSource();
            bufferSource.buffer = slicedBuffer; // Set the new sliced buffer
            bufferSource.connect(this.audioContext.destination);
            bufferSource.start(startTime);

            this.activeBufferSources.push(bufferSource);

            // Remove from active sources once playback ends
            bufferSource.onended = () => {
                this.removeSource(bufferSource);
            };

            // Update the start time for the next note
            startTime += slicedBuffer.duration;
        });

        return audioLength * 1000;

    }


    public static delay(ms: number): Promise<void> {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    //If audio context has not been declared, ensure it is initialized
    private static initializeAudioContext(): void {
        if (typeof window !== 'undefined') {
            this.audioContext = new window.AudioContext();
        } else {
            this.audioContext = new AudioContext();
        }
    }

    private static initPlayback(): void{
        //Set up url mappings
        if(!this.audioContext){
            this.initializeAudioContext();
        }

        //Stop previous playback audio
        this.stopAllActiveSources();
    }
}
